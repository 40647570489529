export function handleFirebaseAuthError(error, provider = false) {
  switch (error.code) {
    case 'auth/account-exists-with-different-credential':
      // auth().fetchSignInMethodsForEmail(emailAddress) will get you the provider but you need to know the email address
      // Once the user is signed in, the original credential can be linked to the user with firebase.User.linkWithCredential
      return 'You appear to have already signed up with this email address. Please try logging in using a different method.';
    case 'auth/invalid-credential':
      // Thrown if the credential is malformed or has expired.
      return 'Sorry, your login seems to have expired';
    case 'auth/operation-not-allowed':
      // Thrown if the type of account corresponding to the credential is not enabled. Enable the account type in the Firebase Console, under the Auth tab.
      return (
        'Sorry, that provider (' + provider + ') is not currently available.'
      );
    case 'auth/user-disabled':
      // Thrown if the user corresponding to the given credential has been disabled.
      return 'Sorry, that account is disabled';
    case 'auth/wrong-password':
    case 'auth/user-not-found':
      // Thrown if the user corresponding to the given credential has been disabled.
      return 'Login details are not recognised. Please check your email/password is correct.';
    case 'auth/email-already-in-use':
      return 'This email is already in use, please login to your existing account.';
    case 'auth/network-request-failed':
      return 'There is an issue with your internet connection. Please try again later.';
    case 'auth/too-many-requests':
      return 'You have tried to login too many times, please try again later';
    case 'auth/weak-password':
      return 'Password too weak. Please make sure it is at least 8 characters long with a combination of letters and numbers';
    case 'auth/web-storage-unsupported':
      return 'This browser is not supported. Please use a different browser or stop using incognito mode.';
    default:
      // Something else
      return 'Sorry, there was an issue authenticating you';
  }
}
