export function isValidEmail(email) {
  const regEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return !(regEx.test(email) === false);
}

export function isValidPhone(phone) {
  const strippedPhone = phone.replace(/ /g, '');
  // Check the number is the correct length (a few UK numbers can be 10 digits)
  if (strippedPhone.length < 10 || strippedPhone.length > 11) {
    return false;
    // Check the number starts with a recognised uk prefix
  } else if (!['01', '02', '07'].includes(strippedPhone.substring(0, 2))) {
    return false;
  }
  return true;
}

/**
 * <p>Used to easily concatenate css module class names without having to use string templates.</p>
 * <p>Pass in each class name as an argument.</p>
 * @param names
 * @returns {string}
 */
export function classnames(...names) {
  return names.join(' ');
}

/**
 * Returns 'Today' or 'Tomorrow' if applicable, or the date in UK format separated by forward slashes
 * Expects date to be passed in format i.e. YYYY-MM-DD as that's what server returns during timeSlot booking
 */
export function dateTextDisplay(date) {
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  const todayString = today.toLocaleDateString('en-CA');
  const tomorrowString = tomorrow.toLocaleDateString('en-CA');
  if (date === todayString) {
    return 'Today';
  } else if (date === tomorrowString) {
    return 'Tomorrow';
  } else {
    return date.split('-').reverse().join('/');
  }
}
