import React, { useState, useEffect } from 'react';
import { useStores } from '../../context/context';

import RepatchInput from '../../components/RepatchInput';
import RepatchButton from '../../components/RepatchButton';
import LocationSearch from '../../components/LocationSearch';
import { useHistory } from 'react-router-dom';
import styles from './CustomerPage.module.css';
import { useAuth } from '../../context/firebase-context';
import { observer } from 'mobx-react';
import { classnames } from '../../helpers/misc';
import { handleFirebaseAuthError } from '../../helpers/auth';

const CustomerPage = () => {
  const auth = useAuth();
  const history = useHistory();
  const { authStore, ticketStore } = useStores();

  // Used to store and update customer state before saving
  const [customer, setCustomer] = useState({
    ...authStore.customer,
    currentPassword: '',
    newPassword: '',
    passwordRepeat: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    if (!authStore.isAuthenticated) {
      history.push('/login', { path: '/profile' });
    }
  }, [auth]);

  const updateCustomer = (event) => {
    const { name, value } = event.target;
    if (event.type === 'click') {
      setCustomer((prev) => {
        return { ...prev, [name]: !customer[name] };
      });
    } else if (event.type === 'focus') {
      setError('');
    } else {
      setCustomer((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };

  // A bit janky because of the way the ticket address lookup is currently handled, but have to pull the values from TicketStore
  // FIXME: Update this once the TicketStore address handling is optimised
  const updateCustomerAddress = () => {
    setCustomer((prev) => {
      return {
        ...prev,
        address: ticketStore.address,
        postcode: ticketStore.postcode,
      };
    });
  };

  const saveCustomerDetails = async () => {
    if (customer.currentPassword && customer.newPassword) {
      if (customer.newPassword.length < 6) {
        setError('Your new password must be at least 6 characters long');
        return false;
      } else if (customer.newPassword !== customer.passwordRepeat) {
        setError('Passwords must match');
        return false;
      } else {
        try {
          await auth.updatePassword(
            customer.email,
            customer.currentPassword,
            customer.newPassword
          );
          setCustomer((prev) => {
            return {
              ...prev,
              currentPassword: '',
              newPassword: '',
              passwordRepeat: '',
            };
          });
        } catch (e) {
          setError(e.message);
          return false;
        }
      }
    }

    const { currentPassword, newPassword, passwordRepeat, ...data } = customer;

    if (customer.email !== authStore.customer.email) {
      try {
        const idToken = await auth.user.getIdToken();
        data.idToken = idToken;
      } catch (e) {
        const errorMessage = handleFirebaseAuthError(e);
        setError(errorMessage);
      }
    }

    await authStore.update(data);
    if (!authStore.hasFailed) {
      // TODO: Replace this with a nicer messaging system
      alert('Saved successfully');
    } else {
      setError(authStore.errorMessage);
    }
  };

  return (
    <div className={styles.container}>
      <h1>My Profile</h1>
      <RepatchInput
        label='Firstname'
        name='firstname'
        onChange={updateCustomer}
        onFocus={updateCustomer}
        value={customer.firstname}
        disabled={authStore.isFetching}
      />
      <RepatchInput
        label='Surname'
        name='surname'
        onChange={updateCustomer}
        onFocus={updateCustomer}
        value={customer.surname}
        disabled={authStore.isFetching}
      />
      <RepatchInput
        label='Phone'
        name='phone'
        onChange={updateCustomer}
        onFocus={updateCustomer}
        value={customer.phone}
        disabled={authStore.isFetching}
      />
      <RepatchInput
        label='Email'
        name='email'
        onChange={updateCustomer}
        onFocus={updateCustomer}
        value={customer.email}
        disabled={
          authStore.isFetching || authStore.customer.authProvider !== 'password'
        }
      />
      {authStore.customer.authProvider !== 'password' && (
        <p className={styles.small}>
          Cannot change email because you are using{' '}
          {authStore.customer.authProvider} to login{' '}
        </p>
      )}
      <p className={styles.inputLabel}>Address</p>
      <LocationSearch onSelectLocation={updateCustomerAddress} />
      {authStore.customer.authProvider === 'password' && (
        <>
          <p className={styles.inputLabel}>Update Password</p>
          <RepatchInput
            label='Current Password'
            name='currentPassword'
            onChange={updateCustomer}
            onFocus={updateCustomer}
            value={customer.currentPassword}
            type='password'
          />
          <RepatchInput
            label='New Password'
            name='newPassword'
            onChange={updateCustomer}
            onFocus={updateCustomer}
            value={customer.newPassword}
            type='password'
          />
          <RepatchInput
            label='Password Repeat'
            name='passwordRepeat'
            onChange={updateCustomer}
            onFocus={updateCustomer}
            value={customer.passwordRepeat}
            type='password'
          />
        </>
      )}
      <p className={styles.inputLabel}>Email Preferences</p>
      <div className={styles.emailPreferences}>
        <div className={classnames(styles.checkboxContainer, styles.small)}>
          <input
            type='checkbox'
            name='essential'
            value='1'
            checked
            disabled
            className={styles.checkbox}
          />{' '}
          Essential order emails
        </div>
        <div className={classnames(styles.checkbox, styles.small)}>
          <input
            type='checkbox'
            name='receivePromo'
            checked={customer.receivePromo}
            onClick={updateCustomer}
            readOnly
            className={styles.checkbox}
          />{' '}
          Promotional emails
        </div>
      </div>
      <p className={styles.error}>{error}</p>
      <RepatchButton
        label='SUBMIT'
        style={{ marginTop: 30 }}
        onClick={saveCustomerDetails}
        disabled={authStore.isFetching}
      />
    </div>
  );
};

export default observer(CustomerPage);
