import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import repatchFetch from '../../helpers/fetch';

import { useStores } from '../../context/context';
import LoadingAnimation from '../../components/LoadingAnimation';
import BigBtn from '../../components/BigBtn';

function SystemPage(props) {
  const { ticketStore } = useStores();
  const [ticket, setTicket] = useState(false);

  useEffect(() => {
    async function loadTicket() {
      var getRes = await repatchFetch('tickets/getSession');
      const ticket = getRes.ticket;
      if (ticket) setTicket(ticket);
    }
    if (!ticket) loadTicket();
  }, []);

  const handleSubmit = async (ev) => {
    await ticketStore.submitFeedback(ticket.ticketID);
  };

  return (
    <div className='container'>
      {!ticket ? (
        <LoadingAnimation />
      ) : (
        <div>
          <h1 style={{ marginBottom: 30 }}>System Debug</h1>
          <h2>Ticket Info (#{ticket.ticketID})</h2>
          <form>
            <div className='ticketInfo'>
              Technician {ticket.technician.firstname}{' '}
              {ticket.technician.surname}:
            </div>
            <div className='footerContainer'>
              <BigBtn title='DEBUG' onClick={handleSubmit} />
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default observer(SystemPage);
