import React from 'react';
import spinner from '../img/loading.gif';

class LoadingAnimation extends React.Component {
  render() {
    return <img src={spinner} alt='loading' />;
  }
}

export default LoadingAnimation;
