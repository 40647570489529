import React from 'react';
import { classnames } from '../helpers/misc';
import './styles/loadingSpinner.css';

function LoadingSpinner({ style, styling }) {
  return (
    <div style={styling} className={classnames('lds-dual-ring', style)}></div>
  );
}

export default LoadingSpinner;
