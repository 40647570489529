import React from 'react';
import styles from './styles/RepatchInput.module.css';

function RepatchInput({
  label,
  value,
  onChange,
  onFocus,
  placeholder,
  name,
  ...inputProps
}) {
  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      <input
        className={styles.input}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        name={name}
        placeholder={placeholder}
        {...inputProps}
      />
    </div>
  );
}

export default RepatchInput;
