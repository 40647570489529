import { capitalize, upperFirst } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useStores } from '../../context/context';
import sharedStyles from '../../styles/shared.module.css';
import DeviceList from './components/DeviceList';
import styles from './DevicePage.module.css';

function DeviceListPage({ match }) {
  const history = useHistory();
  const { deviceStore, ticketStore } = useStores();
  const [devices, setDevices] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const type = history.location.pathname.replace('/', '').split('-')[0];
  const typeID = type === 'phone' ? 1 : type === 'tablet' ? 2 : 3;
  const manufacturer = match.params.manufacturer;

  useEffect(() => {
    async function loadDevices() {
      await deviceStore.getDevices(typeID);

      const params = new URLSearchParams(history.location.search);

      if (params.get('s')) {
        const value = params.get('s').replace('_', ' ');
        setSearchValue(value);
      } else if (manufacturer) {
        onManufacturerSearch(upperFirst(manufacturer));
      } else {
        setDevices(deviceStore.devices);
      }
    }
    loadDevices();
  }, [type]);

  useEffect(() => {
    ticketStore.clearTicket();
  }, []);

  useEffect(() => {
    if (searchValue.length > 0) {
      onDeviceSearchChange(searchValue);
    } else {
      onDeviceSearchChange('');
    }
  }, [searchValue]);

  const onDeviceSearchChange = (value) => {
    if (value.length === 0) {
      setDevices(deviceStore.devices);
      return;
    }
    const regex = new RegExp(value, 'i');
    let searchDevices = [];
    deviceStore.devices.forEach((manufacturerDevices) => {
      const matchingDevices = manufacturerDevices.devices.filter((device) =>
        regex.test(device.device)
      );

      // Return search results based on manufacturer name or device name
      if (
        manufacturerDevices.manufacturer
          .toLowerCase()
          .includes(value.toLowerCase())
      ) {
        searchDevices.push({ ...manufacturerDevices });
      } else if (matchingDevices.length > 0) {
        searchDevices.push({
          ...manufacturerDevices,
          devices: matchingDevices,
        });
      }
    });
    setDevices(searchDevices);
  };

  const onManufacturerSearch = (value) => {
    const manufacturer = deviceStore.devices.filter(
      (manufacturer) => manufacturer.manufacturer === value
    );
    if (manufacturer.length > 0) {
      setDevices(manufacturer);
    } else {
      setDevices(deviceStore.devices);
    }
  };

  const onDeviceClick = (device) => {
    const url = `/${type}-repair/${device.manufacturer}/${device.device
      .replaceAll(' ', '_')
      .replaceAll('/', '~')}`;

    history.push(url);
  };

  return (
    <div className={sharedStyles.container}>
      <Helmet>
        <title>Repatch - {upperFirst(type)} Repair</title>
      </Helmet>
      <p style={{ fontWeight: 500 }}>{capitalize(type)} Models</p>
      <input
        className={styles.searchInput}
        placeholder={`Type in ${type} model...`}
        onChange={(event) => setSearchValue(event.target.value)}
        value={searchValue}
      />
      {!deviceStore.isFetching && devices ? (
        <DeviceList devices={devices} onDeviceClick={onDeviceClick} />
      ) : (
        <div style={{ alignSelf: 'center', marginTop: 20 }}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
}

export default DeviceListPage;
