import repatchFetch from '../../helpers/fetch';

const route = 'devices';

const deviceApi = {
  getDeviceTypes: async () => {
    return repatchFetch(`${route}/getDeviceTypes`);
  },
  getDevices: async (params) => {
    return repatchFetch(`${route}/list`, params);
  },
  getDevice: async (params) => {
    return repatchFetch(`${route}/get`, params);
  },
  addDeviceModel: async (params) => {
    return repatchFetch(`${route}/models/add`, params);
  },
};

export default deviceApi;
