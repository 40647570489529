import React, { useEffect, useState } from 'react';
import sharedStyles from '../../styles/shared.module.css';
import styles from './Home.module.css';
import phoneImg from '../../assets/images/phone.png';
import tabletImg from '../../assets/images/tablet.png';
import laptopImg from '../../assets/images/laptop.png';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../context/context';
import HelperBox from '../../components/HelperBox';
import { useAnalytics } from '../../context/analytics-context';
import PromoCode from '../../components/PromoCode';

function Home() {
  const history = useHistory();
  const { ticketStore, authStore } = useStores();
  const { getActivePromo } = useAnalytics();

  const [promo, setPromo] = useState(false);

  useEffect(() => {
    ticketStore.clearTicket();
    getActivePromo().then((promo) => {
      setPromo(promo);
    });
  }, []);

  const onClick = (type) => {
    history.push(`/${type.toLowerCase()}-repair`, { type });
  };

  return (
    <>
      {promo ? <PromoCode promo={promo} /> : <div />}
      <div className={styles.container}>
        <div
          className={styles.leftContainer}
          style={{ flex: authStore.isAuthenticated ? 'none' : 1 }}
        >
          <div
            className={sharedStyles.container}
            style={{
              justifyContent: 'center',
              width: '100%',
              maxWidth: 600,
            }}
          >
            <DeviceTypeButton type='Phone' onClick={onClick} />
            <DeviceTypeButton type='Tablet' onClick={onClick} />
            <DeviceTypeButton type='Laptop' onClick={onClick} />
          </div>
        </div>
        {!authStore.isAuthenticated && !window.isNativeApp && (
          <div className={styles.rightContainer}>
            <HelperBox />
          </div>
        )}
      </div>
    </>
  );
}

function DeviceTypeButton({ type, onClick }) {
  let imageSrc;

  switch (type) {
    case 'Phone':
      imageSrc = phoneImg;
      break;
    case 'Tablet':
      imageSrc = tabletImg;
      break;
    case 'Laptop':
      imageSrc = laptopImg;
      break;
    default:
      break;
  }
  return (
    <div className={styles.buttonContainer} onClick={() => onClick(type)}>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={imageSrc} alt='device-type' />
      </div>

      <div className={styles.buttonTextContainer}>
        <h3 className={styles.title}>{type} Repair</h3>
        <p className={styles.subtitle}>
          Click here to get your {type} repaired
        </p>
      </div>
    </div>
  );
}

export default Home;
