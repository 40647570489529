import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAnalytics } from '../context/analytics-context';
import RepatchButton from './RepatchButton';
import styles from '../pages/Payments/PaymentPage.module.css';

function CheckoutForm({ ticket, updateCustomerData }) {
  const stripe = useStripe();
  const analytics = useAnalytics();
  const elements = useElements();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleStripePayment = async (event) => {
    event.preventDefault();
    setError('');
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }
    setIsLoading(true);
    try {
      await updateCustomerData();
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}/track/RE${ticket.ticketID}`,
        },
      });
      console.log(error);
      let errorMessage;
      if (error.type === 'validation_error') {
        // element handles validation error messages, show no additional message
        return;
      }
      switch (error.code) {
        case 'payment_intent_unexpected_state':
          if (error.payment_intent.status === 'succeeded') {
            // The error was because the payment intent has already been resolved. Navigate to tracking.
            history.push(
              `/track/RE${ticket.ticketID}?payment_intent=${error.payment_intent.id}`
            );
            return;
          } else {
            errorMessage =
              'A payment error occurred. Please try again or contact us if the problem persists.';
          }
        case 'incomplete':
          return;
        default:
          errorMessage =
            'A payment error occurred. Please try again or contact us if the problem persists.';
      }
      setError(errorMessage);
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      <form onSubmit={handleStripePayment}>
        <PaymentElement />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '1rem 0',
          }}
        >
          <RepatchButton label='Pay' disabled={isLoading} />
        </div>
      </form>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
}

export default CheckoutForm;
