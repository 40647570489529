import React, { useEffect, useState } from 'react';
import RepatchButton from '../../components/RepatchButton';
import sharedStyles from '../../styles/shared.module.css';
import styles from './Auth.module.css';
import { useStores } from '../../context/context';
import { useAuth } from '../../context/firebase-context';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { classnames, isValidEmail } from '../../helpers/misc';
import RepatchInput from '../../components/RepatchInput';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { handleFirebaseAuthError } from '../../helpers/auth';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useAnalytics } from '../../context/analytics-context';

function AuthPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [formState, setFormState] = useState({
    email: '',
    password: '',
    error: '',
  });
  const [useEmail, setUseEmail] = useState(true);

  const { authStore, ticketStore } = useStores();
  const auth = useAuth();
  const analytics = useAnalytics();

  const history = useHistory();

  useEffect(() => {
    async function getFirebaseRedirect() {
      try {
        const { user } = await firebase.auth().getRedirectResult();
        if (!user) {
          setIsLoading(false);
        }
      } catch (e) {
        const errorMessage = handleFirebaseAuthError(e);
        setFormState((prev) => {
          return { ...prev, error: errorMessage };
        });
        setIsLoading(false);
      }
    }

    // Listen for user navigation events, if user navigates, unset the redirect localStorage so future logins aren't messed up
    const unsubscribe = history.listen((location, action) => {
      if (action === 'POP' || action === 'PUSH') {
        window.localStorage.removeItem('redirect');
      }
    });

    getFirebaseRedirect();

    async function nativeAppLogin() {
      await authStore.serverLogin(window.token);
      if (authStore.isAuthenticated) {
        doRedirect();
      } else {
        setIsLoading(false);
      }
    }
    if (window.isNativeApp && window.token) {
      setIsLoading(true);
      nativeAppLogin();
    }
    return () => {
      // unscribe from the navigation listener on unmount
      unsubscribe();
    };
  }, []);

  const doRedirect = async () => {
    // If there is a redirect key in either the location state or persisted in localStorage, preferentially navigate there on login
    const persistedRedirect = history.location.state
      ? history.location.state.path
      : window.localStorage.getItem('redirect');
    if (persistedRedirect) {
      window.localStorage.removeItem('redirect');
      history.push(persistedRedirect);
    } else if (ticketStore.currentTicket) {
      // We are trying to either create or repatch a ticket
      if (ticketStore.currentTicket.ticketID === 0) {
        // We are working with ticket data that is stored in state as an intermediate which we auth the customer, once the auth completes, send that
        // data to the server and create a ticket on the db
        const ticket = await ticketStore.startTicket(ticketStore.currentTicket);
        if (!ticketStore.hasFailed) {
          analytics.logEvent('create_ticket', {
            ticketID: ticket.ticketID,
            modelID: ticket.deviceID,
            repairTypeID: ticket.repairType.repairTypeID,
          });
          history.push('/ticket');
        }
      } else {
        // A ticket has already been created, but needs starting and assigning to the logged in user, call RMD
        await ticketStore.repatchMyDevice();
        if (!ticketStore.hasFailed) {
          analytics.logEvent('begin_checkout', {
            currency: 'GBP',
            items: [
              {
                item_id: ticketStore.currentTicket.ticketID,
              },
            ],
          });
          history.push('/ticket/pay');
        }
      }
    } else {
      history.push('/');
    }
  };

  useEffect(() => {
    async function doLogin() {
      const idToken = await auth.user.getIdToken();

      await authStore.serverLogin(idToken);
      if (authStore.isAuthenticated) {
        analytics.logEvent('login', {
          method: authStore.customer.authProvider,
        });
        doRedirect();
      } else if (authStore.hasFailed) {
        setFormState((prev) => {
          return { ...prev, error: authStore.errorMessage };
        });
      }
    }

    if (auth.user) {
      // If email is not verified for an email/password user, reject the login and display an error
      // if (
      //   !auth.user.emailVerified &&
      //   auth.user.providerData[0].providerId === 'password'
      // ) {
      //   setFormState((prev) => {
      //     return {
      //       ...prev,
      //       error: 'Please verify your email before logging in',
      //     };
      //   });
      //   // Have to logout the firebase user on error
      //   auth.signOut();
      //   return false;
      // }
      doLogin();
    }

    // Because we have to use redirects instead of popups for Firebase social logins (to support facebook browser)
    // any redirect has to be persisted in localStorage so it can still be peformed when returning
    if (history.location.state && history.location.state.path) {
      window.localStorage.setItem('redirect', history.location.state.path);
    }
  }, [authStore, auth, history]);

  const signIn = async (provider) => {
    setIsLoading(true);

    if (
      provider === 'password' &&
      (!formState.email ||
        !formState.password ||
        !isValidEmail(formState.email))
    ) {
      setFormState((prev) => {
        return { ...prev, error: 'Please enter a valid email and password' };
      });
      return false;
    }

    try {
      await auth.signInWithProvider(
        provider,
        formState.email,
        formState.password
      );
    } catch (e) {
      setFormState((prev) => {
        return { ...prev, error: e.message };
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateFormState = (event) => {
    if (event.type === 'focus') {
      setFormState((prev) => {
        return {
          ...prev,
          error: '',
        };
      });
    } else {
      setFormState((prev) => {
        return { ...prev, [event.target.name]: event.target.value };
      });
    }
  };

  const onForgottenPasswordClick = async () => {
    if (formState.email.length < 6 || !isValidEmail(formState.email)) {
      setFormState((prev) => {
        return { ...prev, error: 'Please enter a valid email address' };
      });
      return false;
    } else {
      setIsLoading(true);
      try {
        await auth.sendPasswordResetEmail(formState.email);
        alert('Password reset email sent.');
      } catch (e) {
        setFormState((prev) => {
          return { ...prev, error: e.message };
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className={sharedStyles.container}>
      <h1 className={styles.title}>Sign In</h1>

      {/* <div className={styles.buttonContainer}>
        <RepatchButton
          buttonType='google'
          label='Continue with Google'
          onClick={() => signIn('google')}
          disabled={isLoading}
        />
        <RepatchButton
          buttonType='apple'
          label='Continue with Apple'
          onClick={() => signIn('apple')}
          disabled={isLoading}
        />
        <RepatchButton
          buttonType='facebook'
          label='Continue with Facebook'
          onClick={() => signIn('facebook')}
          disabled={isLoading}
        />
        <RepatchButton
          buttonType='email'
          label='Continue with Email'
          onClick={() => setUseEmail(!useEmail)}
          disabled={isLoading}
        />
      </div> */}
      {formState.error && <p className={styles.error}>{formState.error}</p>}
      <form
        onSubmit={(event) => {
          event.preventDefault();
          signIn('password');
        }}
        className={classnames(styles.loginForm, useEmail && styles.visible)}
      >
        <RepatchInput
          label='Email'
          name='email'
          value={formState.email}
          onChange={updateFormState}
          onFocus={updateFormState}
        />
        <RepatchInput
          label='Password'
          name='password'
          type='password'
          value={formState.password}
          onChange={updateFormState}
          onFocus={updateFormState}
        />
        <RepatchButton label='Submit' disabled={isLoading} />
        <div className={styles.linkContainer}>
          <p style={{ fontSize: 10 }}>
            <Link to={{ pathname: '/signup' }}>Register your account</Link>
          </p>
          <p
            style={{
              fontSize: 10,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={onForgottenPasswordClick}
          >
            Forgotten password?
          </p>
        </div>
      </form>
      {isLoading && <LoadingSpinner styling={{ alignSelf: 'center' }} />}
    </div>
  );
}

export default observer(AuthPage);
