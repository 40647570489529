import React from 'react';
import './styles/stars.css';

class StarRating extends React.Component {
  getStarRating(x, outOf) {
    outOf = outOf || 5;
    if (x > outOf) x = outOf;
    var stars = [];
    var n = 0;
    // Full stars
    for (var i = 0; i < Math.floor(x); i++) {
      n++;
      stars.push(
        <span key={i} onClick={this.props.onClick} id={'star' + n}>
          &#9733;
        </span>
      );
    }
    // Half star
    if (x > Math.floor(x))
      stars.push(
        <i key='half'>
          &#9733;<b>&#9733;</b>
        </i>
      );
    // Blank stars
    for (i = stars.length; i < outOf; i++) {
      n++;
      stars.push(
        <i key={i} onClick={this.props.onClick} id={'star' + n}>
          &#9733;
        </i>
      );
    }
    return stars;
  }

  render() {
    return (
      <div className='starRating'>{this.getStarRating(this.props.stars)}</div>
    );
  }
}

export default StarRating;
