import React from 'react';
import { classnames } from '../helpers/misc';
import styles from './styles/RepatchButton.module.css';
import LoadingSpinner from './LoadingSpinner';

import { IoMailOutline, IoLogoFacebook, IoLogoApple } from 'react-icons/io5';
import { FcGoogle } from 'react-icons/fc';

export default function RepatchButton({
  onClick,
  label,
  icon = false,
  buttonType = false,
  disabled = false,
  style = {},
  ...buttonProps
}) {
  let buttonStyle = styles.defaultButton;
  let textStyle = styles.defaultText;

  switch (buttonType) {
    case 'small':
      buttonStyle = styles.smallButton;
      textStyle = styles.smallText;
      break;
    case 'google':
      buttonStyle = styles.googleButton;
      textStyle = styles.googleText;
      icon = <FcGoogle className={styles.icon} />;
      break;
    case 'facebook':
      buttonStyle = styles.facebookButton;
      icon = <IoLogoFacebook className={styles.icon} />;
      break;
    case 'apple':
      buttonStyle = styles.appleButton;
      icon = <IoLogoApple className={styles.icon} />;
      break;
    case 'email':
      icon = <IoMailOutline className={styles.icon} />;
      break;
    default:
      if (disabled) {
        icon = <LoadingSpinner style={styles.icon} />;
      }
  }

  return (
    <button
      className={classnames(
        styles.button,
        buttonStyle,
        disabled && styles.disabled
      )}
      onClick={onClick}
      disabled={disabled}
      {...buttonProps}
      style={style}
    >
      {icon && icon}
      <p
        disabled={disabled}
        className={classnames(
          styles.text,
          textStyle,
          disabled && styles.disabled
        )}
      >
        {label}
      </p>
    </button>
  );
}
