import React from 'react';
import { classnames } from '../helpers/misc';
import styles from './styles/Modal.module.css';
import { BiX } from 'react-icons/bi';

function Modal({ isOpen, closeModal, children, style = {} }) {
  return (
    <div className={classnames(styles.modal, isOpen && styles.open)}>
      <div className={styles.modalContent} style={style}>
        <div className={styles.modalHeader} onClick={closeModal}>
          <div className={styles.closeButton}>
            <BiX />
          </div>
        </div>
        <div className={styles.modalBody}>{children}</div>
      </div>
    </div>
  );
}

export default Modal;
