import React from 'react';
import { withRouter } from 'react-router';
import { StoreContext } from '../../context/context';
import LoadingAnimation from '../../components/LoadingAnimation';
import StarRating from '../../components/StarRating';
import './Users.css';

class TechnicianPage extends React.Component {
  static contextType = StoreContext;
  userID = 0;

  state = {
    user: {},
  };

  constructor(props) {
    super(props);
    this.userID = this.props.match.params.userID;
  }

  componentDidMount() {
    this.userStore = this.context.userStore;
    this.loadUser(this.userID);
  }

  async loadUser(userID) {
    // If a ticketID was passed, load this
    var user = await this.userStore.getUser(userID);
    this.setState({
      status: 'loaded',
      user: user,
    });
  }

  render() {
    return (
      <div className='pageContainer'>
        {this.state.status === 'loading' ? (
          <LoadingAnimation />
        ) : (
          <Technician user={this.state.user} history={this.props.history} />
        )}
      </div>
    );
  }
}

class Technician extends React.Component {
  static contextType = StoreContext;

  render() {
    var user = this.props.user;
    return (
      <div className='userContainer'>
        <div className='whiteContainer'>
          <div className='userPageHeading'>
            <div
              className='profilePic'
              style={{ backgroundImage: `url("${user.imgUrl}")` }}
            ></div>
            <h2>
              {user.firstname} {user.surname}
            </h2>
          </div>
          <StarRating stars={user.rating} />
          <p>{user.about}</p>
          <button onClick={this.props.history.goBack}>&#8592; Back</button>
        </div>
        <div className='footerContainer'></div>
      </div>
    );
  }
}

// Have to wrap in withRouter in order to use url params in class
export default withRouter(TechnicianPage);
