import { action, makeObservable, observable } from 'mobx';
import ApiBasedStore from './ApiBasedStore';
import { hydrateStore, makePersistable } from 'mobx-persist-store';

export default class DeviceStore extends ApiBasedStore {
  deviceApi;
  deviceTypes = [];
  devices = [];
  customModel = false;

  constructor(rootStore, deviceApi) {
    super(rootStore);
    this.deviceApi = deviceApi;

    makeObservable(this, {
      deviceTypes: observable,
      devices: observable,
      customModel: observable,
      getDeviceTypes: action,
      getDevices: action,
      setCustomModel: action,
    });

    makePersistable(this, {
      name: 'DeviceStore',
      properties: ['customModel'],
    });
  }

  getDeviceTypes = async () => {
    try {
      this.startRequest();
      const { rc, msg, deviceTypes } = await this.deviceApi.getDeviceTypes();

      if (rc === 0) {
        this.deviceTypes = deviceTypes;
      } else {
        this.onError(msg);
      }
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  };

  getDevices = async (deviceTypeID) => {
    try {
      this.startRequest();
      const { rc, msg, devices } = await this.deviceApi.getDevices({
        deviceTypeID,
      });

      if (rc === 0) {
        this.devices = devices;
      } else {
        this.onError(msg);
      }
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  };

  getDevice = async (data) => {
    try {
      this.startRequest();
      const { rc, msg, device } = await this.deviceApi.getDevice(data);

      if (rc === 0) {
        return device;
      } else {
        this.onError(msg);
      }
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  };

  addDeviceModel = async (data, device) => {
    try {
      this.startRequest();
      const { rc, msg, model } = await this.deviceApi.addDeviceModel(data);

      if (rc === 0) {
        return model;
      } else {
        this.onError(msg);
      }
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  };

  setCustomModel(modelData) {
    this.customModel = modelData;
  }
}
