import React from 'react';

function ModelSelect({ device, onChange, value }) {
  // Build a select
  var options = [];
  for (var i in device.models) {
    var model = device.models[i];
    var title = model.model;
    if (title) {
      options.push(
        <option key={i} value={model.modelID}>
          {title}
        </option>
      );
    }
  }
  return (
    <div className='modelSelectBlock'>
      <select
        name='modelID'
        className='repatchSelect'
        onChange={onChange}
        value={value}
      >
        <option value='0'>Select a model</option>
        {options}
        <option value='-1'>+ Add your model</option>
      </select>
    </div>
  );
}

export default ModelSelect;
