import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { useStores } from '../context/context';
import { Helmet } from 'react-helmet';

// System pages
import Home from '../pages/Home/Home';
import NotFound from '../pages/NotFound';
import AuthPage from '../pages/Auth/AuthPage';
import MyOrders from '../pages/MyOrders/MyOrders';
import SignUpPage from '../pages/Auth/SignUpPage';
import DevicePage from '../pages/Devices/DevicePage';
import TicketPage from '../pages/Tickets/TicketPage';
import PaymentPage from '../pages/Payments/PaymentPage';
import TrackingPage from '../pages/Tickets/TrackingPage';
import SystemPage from '../pages/Other/SystemPage';
import FeedbackPage from '../pages/MyOrders/FeedbackPage';
import CustomerPage from '../pages/Customers/CustomerPage';
import TechnicianPage from '../pages/Users/TechnicianPage';
import DeviceListPage from '../pages/Devices/DeviceListPage';
import Logout from '../pages/Auth/Logout';

// Help pages
import GoogleHelpPage from '../pages/Help/GoogleHelpPage';

/*
  Use this array to configure new or edit existing routes. Each object accepts the following keys:
  path: the url path used to access the route
  key: the internal key used when rendering the route
  exact: whether to match only the exact path or to also accept sub paths
  private: whether the route should only be available to authenticated users
  component: the React component that will be rendered by the route
  routes: An array of any subroutes that are associated with the parent
 */
const ROUTES = [
  {
    path: '/',
    key: 'ROOT',
    exact: false,
    component: RenderRoutes,
    routes: [
      {
        path: '/',
        key: 'ROOT',
        exact: true,
        component: Home,
        title: 'Repatch - Choose a Device',
      },
      {
        path: '/login',
        key: 'ROOT',
        exact: true,
        component: AuthPage,
        title: 'Repatch - Login',
      },
      {
        path: '/logout',
        key: 'ROOT',
        exact: true,
        component: Logout,
        title: 'Repatch - Logout',
      },
      {
        path: '/signup',
        key: 'ROOT',
        exact: true,
        component: SignUpPage,
        title: 'Repatch - Sign Up',
      },
      {
        path: '/reset',
        key: 'RESET',
        exact: true,
        component: Home,
      },
      {
        path: '/:deviceType-repair/:manufacturer/:device',
        key: 'DEVICEPAGE',
        component: DevicePage,
        exact: false,
        private: false,
      },
      {
        path: '/:deviceType-repair/:manufacturer?',
        key: 'DEVICELIST',
        component: DeviceListPage,
        exact: false,
        private: false,
      },
      {
        path: '/ticket/auth',
        key: 'TICKETAUTH',
        component: AuthPage,
        exact: true,
        private: false,
      },
      {
        path: '/ticket/pay',
        key: 'TICKETPAY',
        component: PaymentPage,
        exact: true,
        private: true,
        title: 'Repatch - Payment',
      },
      {
        path: '/ticket/:ticketID',
        key: 'TICKET',
        component: TicketPage,
        exact: false,
        private: true,
        title: 'Repatch - Your Quotes',
      },
      {
        path: '/ticket/',
        key: 'TICKET',
        component: TicketPage,
        exact: true,
        private: true,
        title: 'Repatch - Your Quotes',
      },
      {
        path: '/track/RE:ticketID',
        key: 'TICKET',
        component: TrackingPage,
        exact: true,
        private: true,
        title: 'Repatch - Order Tracking',
      },
      {
        path: '/profile',
        key: 'CUSTOMERHOME',
        component: CustomerPage,
        exact: true,
        private: true,
        title: 'Repatch - My Settings',
      },
      {
        path: '/orders',
        key: 'MYORDERS',
        component: MyOrders,
        exact: true,
        private: true,
        title: 'Repatch - My Orders',
      },
      {
        path: '/system',
        key: 'SYSTEM',
        component: SystemPage,
        exact: true,
        private: false,
      },
      {
        path: '/feedback/RE:ticketID',
        key: 'FEEDBACK',
        component: FeedbackPage,
        exact: true,
        private: true,
      },
      {
        path: '/technician/:userID',
        key: 'TECHNICIAN',
        component: TechnicianPage,
        exact: false,
        private: false,
      },
      {
        path: '/help/how-to-find-google-model-number',
        key: 'GOOGLEHELP',
        component: GoogleHelpPage,
        exact: true,
        private: false,
        title: 'Repatch - Google Model Number Help',
      },
    ],
  },
];

// Determines whether the route's component should be rendered or redirected according to application state
// i.e. preventing private routes from being exposed to unauthenticated users
function RenderRoutePage(props, route) {
  if (route.private && !route.isAuthenticated) {
    let path = route.location.pathname;

    if (window.location.search) {
      path += window.location.search;
    }
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { path },
        }}
      />
    );
  } else {
    return <route.component {...props} routes={route.routes} />;
  }
}

// Passes subroutes as props to parent routes that specify them in ROUTES.
function RouteWithSubRoutes(route) {
  return (
    <>
      <Helmet>
        <title>{route.title}</title>
      </Helmet>
      <Route
        path={route.path}
        exact={route.exact}
        render={(props) => RenderRoutePage(props, route)}
      />
    </>
  );
}

// Used to access the route from app.js
export function RenderRoutes({ routes }) {
  const { authStore } = useStores();

  return (
    <Switch>
      {routes.map((route) => {
        return (
          <RouteWithSubRoutes
            key={route.key}
            {...route}
            isAuthenticated={authStore.isAuthenticated}
          />
        );
      })}
      <Route component={NotFound} />
    </Switch>
  );
}

export default ROUTES;
