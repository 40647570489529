// import { action, makeObservable, observable } from "mobx";
import { action, makeObservable, observable, runInAction } from 'mobx';
import { makePersistable, hydrateStore } from 'mobx-persist-store';
import ApiBasedStore from './ApiBasedStore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import CustomerModel from '../models/CustomerModel';

export default class AuthStore extends ApiBasedStore {
  authApi;
  isAuthenticated = false;
  customer = null;
  address = '';
  postcode = '';
  phone = '';

  constructor(rootStore, authApi) {
    super(rootStore);
    this.authApi = authApi;
    makeObservable(this, {
      isAuthenticated: observable,
      customer: observable,
      phone: observable,
      serverLogin: action,
      clearStore: action,
      logout: action,
    });

    makePersistable(this, {
      name: 'AuthStore',
      properties: ['isAuthenticated', 'customer', 'phone'],
    });
  }

  serverLogin = async (idToken) => {
    this.startRequest();
    try {
      const { rc, msg, customer } = await this.authApi.login({
        idToken,
        address: this.rootStore.ticketStore.address,
        postcode: this.rootStore.ticketStore.postcode,
        phone: this.rootStore.authStore.phone,
      });
      if (rc === 0) {
        this.isAuthenticated = true;
        this.phone = '';
        this.customer = new CustomerModel(customer);
      } else {
        this.logout();
        this.onError(msg);
      }
    } catch (e) {
      this.logout();
      this.onError(e);
    } finally {
      this.endRequest();
    }
  };

  logout = async (keepTickets = false) => {
    this.startRequest();
    try {
      const { rc, msg } = await this.authApi.logout();
      if (rc === 0) {
        firebase.auth().signOut();

        this.clearStore();

        // Sometimes we need to keep the ticket state on logout i.e. if we're logging out
        // a user with an unverified email.
        if (!keepTickets) {
          this.rootStore.ticketStore.clearStore();
        }
      } else {
        this.onError(msg);
      }
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  };

  update = async (data) => {
    this.startRequest();

    try {
      const { rc, msg, customer } = await this.authApi.update(data);

      if (rc === 0) {
        this.customer.update(customer);
      } else {
        this.onError(msg);
      }
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  };

  async checkForPersistedUser() {
    await hydrateStore(this);
    // Convert the hydrated object into a CustomerModel so class methods are available
    if (this.customer) {
      runInAction(() => {
        this.customer = new CustomerModel(this.customer);
      });
    }

    await this.rootStore.ticketStore.hydrateStore();
  }

  clearStore = () => {
    this.isAuthenticated = false;
    this.customer = false;
    this.phone = '';
  };
}
