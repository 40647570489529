import React, { useCallback, useEffect, useState } from 'react';
import styles from './styles/LocationSearch.module.css';
import iconSearch from '../img/icon-search.png';
import debounce from 'lodash.debounce';
import LoadingSpinner from './LoadingSpinner';
import { useStores } from '../context/context';
import { useAnalytics } from '../context/analytics-context';

function LocationSearch({ onSelectLocation }) {
  const { authStore, ticketStore } = useStores();
  const analytics = useAnalytics();
  const [isFetching, setIsFetching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [address, setAddress] = useState(
    authStore.customer && authStore.customer.address
      ? `${authStore.customer.address}, ${authStore.customer.postcode}`
      : ticketStore.address && `${ticketStore.address}, ${ticketStore.postcode}`
  );
  const [error, setError] = useState('');

  useEffect(() => {
    if (authStore.customer && authStore.customer.address) {
      ticketStore.updateAddress(
        authStore.customer.address.split(', '),
        authStore.customer.postcode
      );
    }
  }, []);

  const autoCompleteSearch = async (value) => {
    if (value) {
      setIsFetching(true);
      fetch(
        `${
          process.env.REACT_APP_API_URL
        }api/customers/address/autocomplete?address=${encodeURIComponent(
          value
        )}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.rc === 2) {
            setError('Sorry, something went wrong!');
            setAddress('');
            setSearchResults([]);
            ticketStore.updateAddress([], ''); // clear out any previous address on error
            onSelectLocation();
            setIsFetching(false);
          } else {
            setSearchResults(data.suggestions);
            setIsFetching(false);
          }
        });
    } else {
      setSearchResults([]);
    }
  };

  const debouncedAutoCompleteSearch = useCallback(
    debounce(autoCompleteSearch, 500),
    []
  );

  const setTicketAddress = (value) => {
    fetch(
      `${process.env.REACT_APP_API_URL}api/customers/address/confirm?addressId=${value}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.rc === 2) {
          setError('Sorry, something went wrong!');
          setAddress('');
          setSearchResults([]);
          ticketStore.updateAddress([], ''); // clear out any previous address on error
          onSelectLocation();
        } else if (data.msg === 'out_of_area') {
          analytics.logEvent('outside_area', {
            postcode: data.postcode,
            location: data.town_or_city ?? '',
          });
          setError('Sorry! Repatch is not available in your area yet.');
          setAddress('');
          setSearchResults([]);
          ticketStore.updateAddress([], ''); // clear out any previous address on error
          onSelectLocation();
        } else {
          ticketStore.updateAddress(data.formatted_address, data.postcode);
          setSearchResults([]);
          setAddress(ticketStore.location);
          onSelectLocation(true);
        }
      });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.inputContainer}>
          {isFetching ? (
            <LoadingSpinner />
          ) : (
            <img
              src={iconSearch}
              alt='location'
              className={styles.searchIcon}
            />
          )}
          <input
            type='text'
            name='location'
            autoComplete='off'
            placeholder='Start typing your address'
            value={address}
            onChange={(event) => {
              setAddress(event.target.value);
              debouncedAutoCompleteSearch(event.target.value);
            }}
            className={styles.locationInput}
            onFocus={() => setError('')}
          />
        </div>

        <div className={styles.resultContainer}>
          {searchResults.map((result) => (
            <p
              className={styles.locationResult}
              onClick={() => setTicketAddress(result.id)}
              key={result.id}
            >
              {result.address}
            </p>
          ))}
        </div>
      </div>
      {error && <p className={styles.error}>{error}</p>}
    </>
  );
}

export default LocationSearch;
