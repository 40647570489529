import ApiBasedStore from './ApiBasedStore';
import repatchFetch from '../../helpers/fetch';
import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { hydrateStore, makePersistable } from 'mobx-persist-store';
import TicketModel from '../models/TicketModel';

export default class TicketStore extends ApiBasedStore {
  ticketApi;
  ticketID = null;
  tickets = [];
  address = '';
  postcode = '';
  quoteID = null;
  bill;
  earliestAvailableDay = null;

  constructor(rootStore, ticketApi) {
    super(rootStore);
    this.ticketApi = ticketApi;

    makeObservable(this, {
      address: observable,
      postcode: observable,
      tickets: observable,
      ticketID: observable,
      quoteID: observable,
      earliestAvailableDay: observable,
      startTicket: action,
      addTickets: action,
      setQuoteID: action,
      updateAddress: action,
      location: computed,
      currentTicket: computed,
    });

    makePersistable(this, {
      name: 'TicketStore',
      properties: ['address', 'postcode', 'ticketID', 'tickets', 'quoteID'],
    });
  }

  clearStore = action(function () {
    this.address = '';
    this.postcode = '';
    this.tickets = [];
    this.ticketID = null;
  });

  clearTicket = action(function () {
    this.ticketID = null;
    this.quoteID = null;
    this.bill = false;
  });

  setQuoteID = function (quoteID) {
    this.quoteID = quoteID;
    this.currentTicket.setAcceptedQuote(quoteID);
  };

  startTicket = async (data) => {
    let req = data;

    // DB saves ticket address as location string, concatenate address and postcode into location
    req.location = `${req.address}, ${req.postcode}`;
    req.ticketID = this.ticketID ?? 0;
    req.customerID =
      this.rootStore.authStore.customer &&
      this.rootStore.authStore.customer.customerID
        ? this.rootStore.authStore.customer.customerID
        : 0;
    req.phone = this.rootStore.authStore.phone;
    req.cookiePref = localStorage.getItem('cookiePref');

    try {
      this.startRequest();
      // If the user is not logged in, we need to save the ticketData to the store,  then authenticate before saving the ticket to the database so we can
      // capture the customerID
      if (!this.rootStore.authStore.isAuthenticated) {
        // If there is already a temp local ticket i.e. ticketID=0 then overwrite it, we only need one
        const index = this.tickets.findIndex((t) => t.ticketID === 0);
        if (index === -1) {
          this.tickets.push(req);
        } else {
          this.tickets[index] = req;
        }
        // Set the ticketID to zero because we're handling a ticket that is being temporarily saved
        this.ticketID = 0;
        return req;
      } else {
        // If the customer is logged in, then just save the ticket to the DB immediately
        const { rc, msg, ticket } = await repatchFetch('tickets/start', req);
        // Should get back a ticket, quotes and technician
        if (rc === 0) {
          this.addTickets([ticket]);

          this.tickets = this.tickets.filter((t) => t.ticketID !== 0); // Get rid of any temporary ticket data (i.e. tickets with ID=0)
          this.ticketID = ticket.ticketID;
          this.earliestAvailableDay = ticket.timeSlots.PICKUP.day;
          return ticket;
        } else {
          this.onError(msg);
        }
      }
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  };

  saveTicket = flow(function* (email) {
    try {
      this.startRequest();

      const { rc, msg, leadID } = yield this.ticketApi.saveTicket({
        email,
        ticketID: this.currentTicket.ticketID,
        quoteID: this.quoteID,
      });

      if (rc !== 0) {
        this.onError(msg);
      } else {
        return leadID;
      }
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  getTicket = async (ticketID) => {
    try {
      this.startRequest();
      const { ticket } = await repatchFetch('tickets/get', { ticketID });
      this.addTickets([ticket]);
      runInAction(() => {
        this.ticketID = parseInt(ticketID);
      });
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  };

  getCustomerTickets = flow(function* () {
    this.startRequest();

    try {
      const { rc, msg, tickets } = yield this.ticketApi.getCustomerTickets(
        this.rootStore.authStore.customer.customerID
      );

      if (rc === 0) {
        this.tickets = [];
        this.addTickets(tickets);
      } else {
        this.onError(msg);
      }
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  // Retrieve a bill for this ticket (a bill will be created if one does not exist)
  getBill = async (ticketID) => {
    this.startRequest();
    const ticket = this.tickets.find((f) => f.ticketID === ticketID);

    try {
      const { rc, msg, bill } = await this.ticketApi.getBill({ ticketID });

      if (rc === 0) {
        ticket.bill = bill ?? false;
      } else {
        this.onError(msg);
      }
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  };

  // !!TBC
  update = async (ticketID, billID, paymentID, customerData) => {
    try {
      this.startRequest();

      const { rc, msg } = await this.ticketApi.updateTicket({
        ticketID,
        customerData,
      });

      if (rc === 0) {
        runInAction(() => {
          // Update the customer record with the new data if not already populated
          if (!this.rootStore.authStore.customer.firstname) {
            this.rootStore.authStore.customer.firstname =
              customerData.firstname;
          }

          if (!this.rootStore.authStore.customer.surname) {
            this.rootStore.authStore.customer.surname = customerData.surname;
          }

          if (!this.rootStore.authStore.customer.phone) {
            this.rootStore.authStore.customer.phone = customerData.phone;
          }

          if (!this.rootStore.authStore.customer.email) {
            this.rootStore.authStore.customer.email = customerData.email;
          }
        });
      } else {
        this.onError(msg);
      }
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  };

  confirm = async (ticketID, paymentIntentID, email) => {
    try {
      this.startRequest();
      const { rc, msg } = await this.ticketApi.confirmTicket({
        ticketID,
        paymentIntentID,
        email,
      });

      if (rc !== 0) {
        this.onError(msg);
      }
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  };

  getTasks = async (ticketID) => {
    var res = await repatchFetch('tickets/getTasks', { ticketID: ticketID });
    return { ticketTasks: res.tasks, ticketPromo: res.promo };
  };

  updateAddress(address, postcode) {
    this.address = address
      .filter((addressLine) => addressLine.length !== 0)
      .join(', ');
    this.postcode = postcode;
  }

  // submitFeedback = async (ticketID, starRating, comments) => {
  //   this.startRequest();
  //   try {
  //     await repatchFetch('tickets/submitFeedback', {
  //       ticketID,
  //       starRating,
  //       comments,
  //     });
  //   } catch (e) {
  //     this.onError(e);
  //   } finally {
  //     this.endRequest();
  //   }
  // };

  repatchMyDevice = async () => {
    if (this.ticketID === null || this.quoteID === null) {
      this.onError('App Error, Please Contact Us');
      return false;
    }

    this.startRequest();
    try {
      const { rc, msg } = await this.ticketApi.repatchMyDevice({
        ticketID: this.ticketID,
        quoteID: this.quoteID,
        timeSlot: this.currentTicket.timeSlot,
      });
      if (rc === 0) {
        const ticket = this.tickets.find((f) => f.ticketID === this.ticketID);
        if (!ticket.customerID) {
          // Ticket should always have a customerID attached, but if not set the currently logged in user
          ticket.setCustomerID(this.rootStore.authStore.customerID);
        }
      } else {
        this.onError(msg);
      }
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  };

  addTickets(tickets) {
    tickets.forEach((ticketData) => {
      const existingTicket = this.tickets.find(
        (f) => f.ticketID === ticketData.ticketID
      );

      if (existingTicket) {
        existingTicket.update(ticketData);
      } else {
        this.tickets.push(new TicketModel(ticketData));
      }
    });

    this.tickets = this.tickets.sort((a, b) => b.ticketID - a.ticketID);
  }

  addPromoCode = flow(function* (code) {
    if (this.currentTicket.promo) {
      this.onError(
        'You already have already added a promotional code for this order.'
      );
    }

    try {
      this.startRequest();

      const { rc, msg, ticket } = yield this.ticketApi.applyCode({
        promoCode: code,
        ticketID: this.ticketID,
      });

      if (rc === 0) {
        // persist the old timeslot
        this.currentTicket.update({
          ...ticket,
          timeSlots: { PICKUP: this.currentTicket.timeSlot },
        });
      } else {
        this.onError(msg);
      }
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  removePromoCode = flow(function* () {
    try {
      this.startRequest();

      const { rc, msg, ticket } = yield this.ticketApi.applyCode({
        promoCode: 'REMOVE',
        ticketID: this.ticketID,
      });

      if (rc === 1) {
        // persist the old timeslot
        this.currentTicket.update({
          ...ticket,
          timeSlots: { PICKUP: this.currentTicket.timeSlot },
        });
      } else {
        this.onError(msg);
      }
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  }).bind(this);

  async hydrateStore() {
    await hydrateStore(this);
    // convert the persisted objects back into TicketModels so class methods are available
    runInAction(() => {
      // If the ticket is a temporary data array i.e. ticketID=0, do not convert to a TicketModel on hydrate or the data will be lost
      this.tickets = this.tickets.map((ticket) => {
        if (ticket.ticketID === 0) {
          return ticket;
        } else {
          return new TicketModel(ticket);
        }
      });
    });
  }

  // Computed properties
  get location() {
    return `${this.address}, ${this.postcode}`;
  }

  get currentTicket() {
    return this.tickets.find((f) => f.ticketID === this.ticketID);
  }
}
