import React from 'react';

function RepairTypeSelect({ repairTypes, onChange, value }) {
  // Build a select
  var options = [];
  for (var i in repairTypes) {
    options.push(
      <option key={i} value={repairTypes[i].repairTypeID}>
        {repairTypes[i].issue}
      </option>
    );
  }
  return (
    <div>
      <select
        name='repairTypeID'
        className='repatchSelect'
        onChange={onChange}
        value={value}
      >
        <option value='0'>Select a repair</option>
        {options}
      </select>
    </div>
  );
}

export default RepairTypeSelect;
