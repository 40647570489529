import React from 'react';
import { useContext, createContext, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/remote-config';
import ReactPixel from 'react-facebook-pixel';

import { useLocation } from 'react-router';

if (firebase.apps.length === 0) {
  firebase.initializeApp({
    apiKey: 'AIzaSyBCLfd2u4QZnJ0PKk-2v3hNRmHLW4MB3z0',
    authDomain: 'repatch-app.firebaseapp.com',
    projectId: 'repatch-app',
    storageBucket: 'repatch-app.appspot.com',
    messagingSenderId: '777728941775',
    appId: '1:777728941775:web:235e10a6a12f38ac52e1ba',
    measurementId: 'G-WFDX3B7ZVW',
    databaseURL:
      'https://repatch-app-default-rtdb.europe-west1.firebasedatabase.app',
  });
}

const AnalyticsContext = createContext();
const analytics = firebase.analytics();
const remoteConfig = firebase.remoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 3000;

export function AnalyticsProvider({ children }) {
  const firebaseAnalytics = useAnalyticsProvider();
  return (
    <AnalyticsContext.Provider value={firebaseAnalytics}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export const useAnalytics = () => {
  return useContext(AnalyticsContext);
};

const useAnalyticsProvider = () => {
  const location = useLocation();

  const logEvent = (event, params) => {
    analytics.logEvent(event, params);

    if (event === 'create_ticket') {
      ReactPixel.trackCustom('create_ticket', params);
    }
  };

  const getActivePromo = async () => {
    if (remoteConfig.lastFetchStatus !== 'success') {
      await remoteConfig.fetchAndActivate();
    }

    // Server APP_ENV is appended to promo key to separate config for envs, REACT_APP_APP_ENV uses full env names, parse local APP_ENV to figure it out
    const env = process.env.REACT_APP_APP_ENV.includes('dev')
      ? 'dev'
      : process.env.REACT_APP_APP_ENV.includes('staging')
      ? 'staging'
      : 'prod';
    const promo = remoteConfig.getValue(`promo_${env}`);
    if (promo.asString()) {
      return JSON.parse(promo.asString());
    } else {
      return false;
    }
  };

  const getPostcodeList = async () => {
    if (remoteConfig.lastFetchStatus !== 'success') {
      await remoteConfig.fetchAndActivate();
    }

    const postcodes = remoteConfig.getValue('postcodes');
    if (postcodes.asString()) {
      return JSON.parse(postcodes.asString());
    } else {
      return false;
    }
  };

  useEffect(() => {
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: process.env.REACT_APP_APP_ENV !== 'production', // enable logs
    };
    ReactPixel.init('253302290158204', {}, options);

    if (window.localStorage.getItem('cookiePref') !== 'all') {
      ReactPixel.revokeConsent();
    }

    if (process.env.REACT_APP_APP_ENV !== 'production') {
      analytics.setAnalyticsCollectionEnabled(false); // do not log events in development/staging
      ReactPixel.revokeConsent(); // do not log events in development/staging
    }
    remoteConfig.fetchAndActivate();
  }, []);

  useEffect(() => {
    analytics.logEvent('page_view', location);
    ReactPixel.pageView();
  }, [location]);

  return {
    logEvent,
    getActivePromo,
    getPostcodeList,
  };
};
