import { observer } from 'mobx-react';
import React from 'react';
import styles from './DeviceList.module.css';

function DeviceList({ devices, onDeviceClick }) {
  return (
    <div className={styles.container}>
      {devices.map((manufacturerDevices) => {
        if (manufacturerDevices.devices.length > 0) {
          return (
            <div
              className={styles.manufacturerContainer}
              key={manufacturerDevices.manufacturerID}
            >
              <p className={styles.manufacturerName}>
                {manufacturerDevices.manufacturer}
              </p>
              {manufacturerDevices.devices.map((device) => (
                <p
                  key={device.deviceID}
                  className={styles.deviceName}
                  onClick={() =>
                    onDeviceClick({
                      ...device,
                      manufacturer: manufacturerDevices.manufacturer,
                    })
                  }
                >
                  {device.device}
                </p>
              ))}
            </div>
          );
        } else {
          return false;
        }
      })}
      <p className='small' style={{ paddingTop: 20 }}>
        If you can't find your device, give us a call on{' '}
        <a href='tel:07475 703912'>07475 703912</a>{' '}
      </p>
    </div>
  );
}

export default observer(DeviceList);
