import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useStores } from '../context/context';
import { IoCloseCircleSharp } from 'react-icons/io5';
import RepatchButton from './RepatchButton';
import RepatchInput from './RepatchInput';

import styles from './styles/PromoCodeInput.module.css';

function PromoCodeInput({ ticket }) {
  const { ticketStore } = useStores();
  const [inputState, setInputState] = useState('');

  useEffect(() => {
    async function setPagePromo() {
      const promoCode = window.sessionStorage.getItem('promo');
      if (promoCode) {
        setInputState(promoCode);
        await ticketStore.addPromoCode(promoCode);
      }
    }
    setPagePromo();
  }, []);

  const addPromoCode = async (event) => {
    event.preventDefault();
    await ticketStore.addPromoCode(inputState);

    if (ticket && !ticket.promo) {
      window.sessionStorage.removeItem('promo');
      alert('Promo invalid. Please check the code and try again.');
    }
  };

  if (ticket && !ticket.promo) {
    return (
      <form onSubmit={addPromoCode} className={styles.container}>
        <RepatchInput
          placeholder='Add a promo code'
          value={inputState}
          onChange={(event) => setInputState(event.target.value)}
        />
        <RepatchButton
          buttonType='small'
          label='Add'
          style={{ marginLeft: 10 }}
          onClick={addPromoCode}
        />
      </form>
    );
  } else {
    return (
      <div className={styles.container}>
        <p className={styles.discountText}>
          {ticket && ticket.promo.discountAmount > 0
            ? `£${ticket.promo.discountAmount}`
            : `${ticket.promo.discountPercentage}%`}{' '}
          discount from code {ticket.promo.promoCode} added
        </p>
        <IoCloseCircleSharp
          className={styles.removeIcon}
          onClick={() => {
            window.sessionStorage.removeItem('promo');
            setInputState('');
            ticketStore.removePromoCode();
          }}
          style={{ marginLeft: 10 }}
        />
      </div>
    );
  }
}

export default observer(PromoCodeInput);
