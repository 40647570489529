import React from 'react';
import { useHistory } from 'react-router-dom';
import RepatchButton from './RepatchButton';
import styles from './styles/Task.module.css';
import { classnames } from '../helpers/misc';
import { IoCheckmarkSharp } from 'react-icons/io5';

const Task = ({ task, first, nextNotStarted }) => {
  const history = useHistory();
  const currentTask = task.status === 'STARTED';
  const completed = task.status === 'COMPLETED';

  const navigateToLink = (path) => {
    history.push(path);
  };

  return (
    <div className={styles.container}>
      <div className={styles.trackContainer}>
        {!first && (
          <div
            className={classnames(
              styles.track,
              completed && !nextNotStarted && styles.trackComplete
            )}
          />
        )}
        <div
          className={classnames(
            styles.lozenge,
            currentTask && styles.current,
            completed && styles.complete,
            completed && first && styles.repatched
          )}
        >
          {completed && <IoCheckmarkSharp className={styles.checkmark} />}
        </div>
      </div>
      <div className={styles.infoContainer}>
        <p
          className={styles.title}
          style={{ paddingTop: completed ? '0.5rem' : 0 }}
        >
          {task.title}
        </p>
        {task.timeEstimate && (
          <p className={styles.timeEstimate}>
            <span className={styles.estimatePrefix}>Estimated time: </span>
            {task.timeEstimate}
          </p>
        )}
        {task.link && (
          <div className={styles.linkContainer}>
            <RepatchButton
              buttonType='small'
              label={task.link.title}
              onClick={() => navigateToLink(task.link.url)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Task;
