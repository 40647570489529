import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { useStores } from '../../context/context';
import RepatchButton from '../../components/RepatchButton';
import RepatchInput from '../../components/RepatchInput';
import StarRating from '../../components/StarRating';
import Modal from '../../components/Modal';
import sharedStyles from '../../styles/shared.module.css';
import styles from './Tickets.module.css';
import Quote from './components/Quote';
import { observer } from 'mobx-react';
import LoadingAnimation from '../../components/LoadingAnimation';
import { useAnalytics } from '../../context/analytics-context';
import { isValidEmail } from '../../helpers/misc';
import PromoCodeInput from '../../components/PromoCodeInput';
import PickupTime from './PickupTime';
import PromoCode from '../../components/PromoCode';
import klarnaImage from '../../assets/images/klarna.png';

function TicketPage() {
  const { ticketStore, authStore } = useStores();
  const history = useHistory();
  const analytics = useAnalytics();
  const [ticket, setTicket] = useState(ticketStore.currentTicket);
  const [errorState, setErrorState] = useState(false);
  const [promo, setPromo] = useState({
    title: '',
  });

  const [modalState, setModalState] = useState({
    open: false,
    email: '',
    error: '',
  });

  const updateModalState = (key, value) => {
    setModalState((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const time = new Date().getHours();
  const open = time >= 10 && time < 18;

  useEffect(() => {
    const getTicket = async (ticketID) => {
      await ticketStore.getTicket(ticketID);
      if (!ticketStore.hasFailed) {
        setTicket(ticketStore.currentTicket);
      } else {
        alert(ticketStore.errorMessage);
        ticketStore.clearTicket();
        history.push('/');
      }
    };

    if (history.location.search) {
      const params = new URLSearchParams(history.location.search);
      getTicket(params.get('ticketID'));
    } else if (!ticket) {
      history.push('/');
    }

    analytics.getActivePromo().then((promo) => {
      setPromo(promo);
    });
  }, []);

  useEffect(() => {
    if (ticket) {
      if (!ticket.technician) {
        setErrorState(
          'Sorry, no technicians are currently available in your area for that fix.'
        );
      } else if (ticket.quotes.length === 0) {
        setErrorState('Sorry, we cannot quote for that fix online.');
      }
      ticket.quotes.forEach((quote) => {
        if (quote.accepted) {
          ticketStore.setQuoteID(quote.quoteID);
        }
      });
    }
  }, [ticket]);

  // const goToTechnician = () => {
  //   history.push('/technician/' + ticket.technician.userID);
  // };

  const repatchMyDevice = async () => {
    if (!authStore.isAuthenticated) {
      history.push('/login');
    } else {
      await ticketStore.repatchMyDevice();
      if (!ticketStore.hasFailed) {
        analytics.logEvent('begin_checkout', {
          currency: 'GBP',
          items: [
            {
              item_id: ticketStore.currentTicket.ticketID,
            },
          ],
        });
        history.push('/ticket/pay');
      } else {
        setErrorState(ticketStore.errorMessage);
      }
    }
  };

  const saveQuote = async () => {
    if (modalState.email.length > 0 && isValidEmail(modalState.email)) {
      await ticketStore.saveTicket(modalState.email);
      if (!ticketStore.hasFailed) {
        alert(
          `Your quote is saved. Your reference number is: RE/${ticketStore.ticketID}`
        );
        setModalState({
          email: '',
          open: false,
          error: '',
        });
      }
    } else {
      updateModalState('error', 'Please enter a valid email address');
    }
  };

  const selectQuote = (quoteID) => {
    ticketStore.setQuoteID(quoteID);
    repatchMyDevice();
  };

  if (!ticket) {
    return <LoadingAnimation />;
  } else {
    return (
      <>
        <PromoCode promo={promo} />
        <div className={sharedStyles.container} style={{ marginTop: 100 }}>
          <div className={styles.ticketPageHeading}>
            <div
              className={styles.profilePic}
              style={{
                backgroundImage: `url("${process.env.REACT_APP_API_URL}${ticket.technician.imgUrl}")`,
              }}
            ></div>
            <h2>
              {ticket.technician.firstname} {ticket.technician.surname}
            </h2>
            <p className='small'>
              {ticket.technician.firstname} {ticket.technician.surname} will be
              fixing your device
            </p>
            <StarRating stars={ticket.technician.rating} />
            {/* <button onClick={goToTechnician} className={styles.profileButton}>
            Profile
          </button> */}
          </div>

          <div className={styles.quotesContainer}>
            {ticket.quotes.map((quote) => {
              return (
                <Quote
                  ticket={ticket}
                  quote={quote}
                  key={quote.quoteID}
                  onClick={() => selectQuote(quote.quoteID)}
                  isSelected={ticketStore.quoteID === quote.quoteID}
                />
              );
            })}
          </div>
          <div>
            <img
              src={klarnaImage}
              style={{ width: '100%', margin: '10px 0 0px 0' }}
            />
          </div>
          <PromoCodeInput ticket={ticket} />
          <PickupTime ticket={ticket} />
        </div>
        <div className={styles.buttonContainer}>
          <RepatchButton
            label='SAVE QUOTE'
            onClick={() => updateModalState('open', true)}
            style={{ flex: 1, margin: '20px 5px 0 5px' }}
            disabled={ticketStore.isFetching}
          />
          <RepatchButton
            label='NEXT'
            onClick={repatchMyDevice}
            style={{ flex: 1, margin: '20px 5px 0 5px' }}
            disabled={ticketStore.isFetching}
          />
        </div>
        <Modal
          isOpen={modalState.open}
          closeModal={() => updateModalState('open', false)}
        >
          <h2>Save Your Quote</h2>
          <p className='small'>
            Need some time to think? No problem, just pop in your email address
            and we'll send you a copy of your quote.
          </p>
          <p className='small' style={{ padding: '1rem 0' }}>
            Or, if you have any questions give us a call on{' '}
            <a href='tel:07475 703912'>07475 703912</a>
          </p>

          <RepatchInput
            label='Email Address'
            placeholder='youremail@address.com'
            disabled={ticketStore.isFetching}
            value={modalState.email}
            onChange={(event) => updateModalState('email', event.target.value)}
            onFocus={() => updateModalState('error', '')}
          />
          <p className={styles.error}>{modalState.error}</p>
          <RepatchButton
            label='Save Quote'
            disabled={ticketStore.isFetching}
            onClick={saveQuote}
          />
        </Modal>
        <Modal
          isOpen={errorState}
          closeModal={() => {
            setErrorState(false);
            history.push('/orders');
          }}
        >
          <h3 style={{ paddingBottom: 20 }}>Give us a Call</h3>

          <p className='large' style={{ paddingBottom: 20 }}>
            {errorState} You can call us on{' '}
            <a href='tel:07475 703912'>07475 703912</a> and we'll sort that out
            for you.
          </p>
          <p style={{ paddingBottom: 20 }}>
            Your quote ref: RE/{ticket.ticketID}
          </p>
          <RepatchButton
            onClick={() => window.open('tel:07475 703912')}
            label='Call Us Now'
          />
        </Modal>
      </>
    );
  }
}

export default observer(TicketPage);
