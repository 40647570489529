import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingAnimation from '../../components/LoadingAnimation';
import { useStores } from '../../context/context';

function Logout() {
  const { authStore } = useStores();
  const history = useHistory();

  useEffect(() => {
    async function doLogout() {
      await authStore.logout();
      if (!authStore.isAuthenticated) {
        if (window.isNativeApp) {
          window.ReactNativeWebView.postMessage('logout_success');
        } else {
          history.push('/');
        }
      }
    }
    doLogout();
  }, []);

  return (
    <div>
      <LoadingAnimation />
    </div>
  );
}

export default Logout;
