import repatchFetch from '../../helpers/fetch';

const route = 'auth';

const authApi = {
  login: async (params) => {
    return repatchFetch(`${route}/login`, { ...params });
  },
  update: async (params) => {
    return repatchFetch(`customers/update`, { ...params });
  },
  logout: async () => {
    return repatchFetch(`${route}/logout`);
  },
};

export default authApi;
