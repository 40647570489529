import React, { useState } from 'react';
import { classnames } from '../helpers/misc';
import { FcInTransit } from 'react-icons/fc';
import { IoIosCopy } from 'react-icons/io';

import styles from './styles/PromoCode.module.css';

function PromoCode({ promo }) {
  const [copied, setCopied] = useState(false);

  const copyPromoCode = () => {
    navigator.clipboard.writeText(promo.promoCode).then(() => {
      setCopied(true);
    });
  };

  return (
    <div
      className={classnames(
        styles.promoContainer,
        promo.title && styles.visible
      )}
    >
      <div>
        <p className={styles.promoText}>{promo.title}</p>
      </div>

      <div className={styles.textContainer}>
        <FcInTransit className={styles.icon} />
        <div className={styles.codeContainer}>
          {copied ? (
            <p>Copied!</p>
          ) : (
            <div className={styles.copyContainer}>
              <p className={styles.promoCode}>{promo.promoCode}</p>
              <button onClick={copyPromoCode} className={styles.copyButton}>
                <IoIosCopy size={25} />
              </button>
            </div>
          )}
        </div>
        <FcInTransit className={styles.icon} />
      </div>
    </div>
  );
}

export default PromoCode;
