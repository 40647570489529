import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import RepatchButton from '../../components/RepatchButton';
import RepatchInput from '../../components/RepatchInput';
import { useStores } from '../../context/context';
import { useAuth } from '../../context/firebase-context';
import { classnames, isValidEmail } from '../../helpers/misc';
import sharedStyles from '../../styles/shared.module.css';
import styles from './Auth.module.css';

function SignUpPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState({
    email: '',
    password: '',
    passwordRepeat: '',
    error: '',
  });
  const { authStore } = useStores();
  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    async function doLogin() {
      const idToken = await auth.user.getIdToken();

      await authStore.serverLogin(idToken);
      if (authStore.hasFailed) {
        setIsLoading(false);
        setFormState((prev) => {
          return { ...prev, error: authStore.errorMessage };
        });
      } else {
        await auth.sendVerificationEmail();
        // Logout the user as the email is never verified for email/password logins, but keep the TicketStore data
        // await authStore.logout(true);
        alert(
          'Thank you for signing up, a link as been sent to your email address. Please click this to verify your email.'
        );

        history.push('/');
      }
    }
    if (auth.user) {
      doLogin();
    }
  }, [auth.user]);

  const updateFormState = (event) => {
    if (event.type === 'focus') {
      setFormState((prev) => {
        return { ...prev, error: '' };
      });
    } else {
      setFormState((prev) => {
        return { ...prev, [event.target.name]: event.target.value };
      });
    }
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return false;
    } else {
      setIsLoading(true);
      try {
        await auth.signInWithProvider(
          'password-signup',
          formState.email,
          formState.password
        );
      } catch (e) {
        setFormState((prev) => {
          return { ...prev, error: e.message };
        });
        setIsLoading(false);
      }
    }
  };

  const validateForm = () => {
    if (!formState.email || !isValidEmail(formState.email)) {
      setFormState((prev) => {
        return { ...prev, error: 'Please enter a valid email' };
      });
      return false;
    } else if (formState.password.length < 6) {
      setFormState((prev) => {
        return { ...prev, error: 'Password must be at least 6 characters' };
      });
      return false;
    } else if (formState.password !== formState.passwordRepeat) {
      setFormState((prev) => {
        return { ...prev, error: 'Passwords must match' };
      });
      return false;
    }

    return true;
  };

  return (
    <div className={sharedStyles.container}>
      <h1 className={styles.title}>Sign Up</h1>
      <form
        className={classnames(styles.loginForm, styles.visible)}
        onSubmit={handleSignup}
      >
        <RepatchInput
          label='Email'
          name='email'
          value={formState.email}
          onChange={updateFormState}
          onFocus={updateFormState}
        />
        <RepatchInput
          label='Password'
          name='password'
          value={formState.password}
          onChange={updateFormState}
          onFocus={updateFormState}
          type='password'
        />
        <RepatchInput
          label='Password Repeat'
          name='passwordRepeat'
          value={formState.passwordRepeat}
          onChange={updateFormState}
          onFocus={updateFormState}
          type='password'
        />
        <p className={styles.error}>{formState.error}</p>
        <RepatchButton label='Submit' disabled={isLoading} />
      </form>
    </div>
  );
}

export default SignUpPage;
