import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const database = firebase.database();

export function logError(error) {
  if (error.metadata) {
    if (error.metadata.status) {
      saveError('server/exception', {
        ...error.metadata,
        message: error.message,
      });
    } else if (error.metadata.rc) {
      saveError('server/error', { ...error.metadata, message: error.message });
    }
  } else {
    saveError('client', { message: error.message });
  }
}

function saveError(table, data) {
  const logListRef = database.ref(
    `/logs/${process.env.REACT_APP_APP_ENV}/${table}/${new Date()}`
  );
  logListRef.set(data);
}
