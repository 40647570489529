import React from 'react';
import styles from './styles/RepatchDropdown.module.css';

function RepatchDropdown({ options, onChange, defaultOption = false }) {
  return (
    <select
      className={styles.select}
      onChange={(event) => {
        if (event.target.value !== '-1') {
          onChange(event.target.value);
        }
      }}
    >
      {options.length > 0 ? (
        <>
          <option value='-1'>
            {defaultOption ? defaultOption : 'Choose an option'}
          </option>
          {options.map((option, index) => (
            <option key={index} value={index} className={styles.option}>
              {option}
            </option>
          ))}
        </>
      ) : (
        <option>None Available</option>
      )}
    </select>
  );
}

export default RepatchDropdown;
