import React, { useEffect, useState } from 'react';
import styles from './PaymentPage.module.css';
import { useStores } from '../../context/context';
import { useHistory } from 'react-router-dom';
import RepatchInput from '../../components/RepatchInput';
import RepatchButton from '../../components/RepatchButton';
import LoadingAnimation from '../../components/LoadingAnimation';
import { observer } from 'mobx-react';
import { IoCheckboxOutline, IoSquareOutline } from 'react-icons/io5';
import { useAnalytics } from '../../context/analytics-context';
import { dateTextDisplay, isValidEmail } from '../../helpers/misc';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../../components/CheckoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

function PaymentPage() {
  const { ticketStore, authStore } = useStores();

  const [ticket, setTicket] = useState(false);
  const [personalFormState, setPersonalFormState] = useState({
    firstname: authStore.customer ? authStore.customer.firstname : '',
    surname: authStore.customer ? authStore.customer.surname : '',
    phone: authStore.customer ? authStore.customer.phone : '',
    email: authStore.customer ? authStore.customer.email : '',
    error: '',
  });
  const [agreedTerms, setAgreedTerms] = useState(false);

  const history = useHistory();

  const params = new URLSearchParams(history.location.search);

  useEffect(() => {
    async function loadTicket() {
      // Check if we are coming in from a payment link with ticketID attached, if so fetch that ticket and show the bill
      if (params.get('ticketID')) {
        await ticketStore.getTicket(parseInt(params.get('ticketID')));
        if (ticketStore.currentTicket) {
          setTicket(ticketStore.currentTicket);
        } else {
          history.push('/orders');
          return false;
        }
        // If not, we are coming in from the quotation page in the application, the ticketID is already set in the ticketStore, fetch the assoicated bill
      } else if (ticketStore.ticketID) {
        await ticketStore.getBill(ticketStore.ticketID);
        setTicket(ticketStore.currentTicket);
      } else {
        history.push('/orders');
      }
    }

    if (!ticket) {
      loadTicket();
    }
  }, []);

  const updatePersonalFormState = (event) => {
    // Clear the error message when focusing the personal or card forms
    if (event.type === 'focus' || event.elementType) {
      setPersonalFormState((prev) => {
        return { ...prev, error: '' };
      });
    } else {
      setPersonalFormState((prev) => {
        return { ...prev, [event.target.name]: event.target.value };
      });
    }
  };

  const validateForm = () => {
    setPersonalFormState((prev) => {
      return { ...prev, error: '' };
    });
    if (personalFormState.firstname.length < 2) {
      setPersonalFormState((prev) => {
        return { ...prev, error: 'You must enter your firstname' };
      });
      return false;
    }

    if (personalFormState.surname.length < 2) {
      setPersonalFormState((prev) => {
        return { ...prev, error: 'You must enter your surname' };
      });
      return false;
    }

    if (!personalFormState.phone) {
      setPersonalFormState((prev) => {
        return { ...prev, error: 'You must enter a valid phone number' };
      });
      return false;
    }

    if (!personalFormState.email && !isValidEmail(personalFormState.email)) {
      setPersonalFormState((prev) => {
        return { ...prev, error: 'You must enter a valid email address' };
      });
      return false;
    }

    if (!agreedTerms) {
      setPersonalFormState((prev) => {
        return { ...prev, error: 'You must agree to the terms of repair' };
      });
      return false;
    }

    return true;
  };

  const updateCustomerData = async (paymentIntentID) => {
    if (!validateForm()) {
      throw new Error('form_validation_incomplete');
    }
    var customerData = {
      customerID: authStore.customer.customerID,
      firstname: personalFormState.firstname,
      surname: personalFormState.surname,
      phone: personalFormState.phone,
      email: personalFormState.email,
      address: ticketStore.address,
      postcode: ticketStore.postcode,
    };
    await ticketStore.update(
      ticket.ticketID,
      ticket.bill.billID,
      paymentIntentID,
      customerData
    );
  };

  const goBackToQuotes = () => {
    history.push('/ticket');
  };

  if (!ticket) {
    return <LoadingAnimation />;
  } else {
    return (
      <Elements
        stripe={stripePromise}
        options={{ clientSecret: ticket.bill.clientSecret }}
      >
        <div className={styles.container}>
          <div className={styles.header}>
            {!params.get('ticketID') && (
              <RepatchButton
                label='Back'
                buttonType='small'
                style={{ top: '-1rem', left: '-1rem' }}
                onClick={goBackToQuotes}
              /> // Don't show back button if we're navigating in from a payment link
            )}
            <h2>
              {ticket.device.device} {ticket.repairType.issue}
            </h2>
          </div>
          <div className={styles.body}>
            <p>
              Order number: {ticket.bill.orderNum || `RE${ticket.ticketID}`}
            </p>
            {
              // only show the amount if there is a bill to be paid
              ticket.bill && (
                <h3>&pound;{(ticket.bill.amountInPence / 100).toFixed(2)}</h3>
              )
            }
            {ticket.timeSlot && (
              <div style={{ marginTop: 10 }}>
                <p style={{ fontWeight: '600' }}>Your Collection Slot:</p>
                <p>
                  {dateTextDisplay(ticket.timeSlot.day)}{' '}
                  {ticket.timeSlot.startTime} - {ticket.timeSlot.endTime}
                </p>
              </div>
            )}
            <div className={styles.personalContainer}>
              <p className={styles.subtitle}>Personal Details</p>
              <RepatchInput
                label='Firstname'
                placeholder='Firstname'
                name='firstname'
                value={personalFormState.firstname}
                onChange={updatePersonalFormState}
                onFocus={updatePersonalFormState}
              />
              <RepatchInput
                label='Surname'
                placeholder='Surname'
                name='surname'
                value={personalFormState.surname}
                onChange={updatePersonalFormState}
                onFocus={updatePersonalFormState}
              />
              <RepatchInput
                label='Phone Number'
                placeholder='Phone'
                name='phone'
                value={personalFormState.phone}
                onChange={updatePersonalFormState}
                onFocus={updatePersonalFormState}
              />
              {!authStore.customer.email && (
                <RepatchInput
                  label='Email'
                  placeholder='email@address.com'
                  name='email'
                  value={personalFormState.email}
                  onChange={updatePersonalFormState}
                  onFocus={updatePersonalFormState}
                />
              )}
            </div>
            {
              // only show the payment screen if there is a bill to be paid
              ticket.bill && (
                <div className={styles.paymentContainer}>
                  <p className={styles.subtitle}>Payment Details</p>
                  <CheckoutForm
                    ticket={ticket}
                    updateCustomerData={updateCustomerData}
                  />
                </div>
              )
            }
            {ticket.openQuote && ticket.bill === false ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <p>Terms of Repair</p>
                <p
                  className='small'
                  style={{
                    marginTop: 10,
                    textAlign: 'justify',
                    lineHeight: 1.5,
                  }}
                >
                  A courier will be dispatched to your address on your chosen
                  date/time to collect your device. Please ensure your device is
                  in a protective bag or case and remove any accessories. Our
                  technicians will assess the damage to your device and we will
                  provide a quote for the repair, this can take up to 3-4
                  working days. We will collect and return your device for free,
                  even if the device is not repairable.{' '}
                  <b>
                    However if we can fix your device and you choose not to go
                    ahead there is an £85 diagnostic fee.
                  </b>{' '}
                  For full terms and conditions please see the link below.
                </p>
              </div>
            ) : (
              ''
            )}
            <div
              className={styles.termsContainer}
              onClick={() => setAgreedTerms(!agreedTerms)}
            >
              {agreedTerms ? <IoCheckboxOutline /> : <IoSquareOutline />}
              <p className='small' style={{ marginLeft: 10 }}>
                Please confirm that you agree to our{' '}
                <a
                  href='https://repatch.co.uk/terms'
                  rel='noreferrer'
                  target='_blank'
                >
                  Terms of Repair
                </a>
              </p>
            </div>
            {
              // if no bill, don't try to accept a card payment, just forward to the tracking screen and send false
              // as the payment intend id, the backend will confirm the ticket and move to free collection
              !ticket.bill && (
                <div>
                  <RepatchButton
                    label='Book Repair'
                    style={{ width: '100%' }}
                    onClick={() => {
                      if (!agreedTerms) {
                        setPersonalFormState((prev) => {
                          return {
                            ...prev,
                            error: 'You must agree to the terms of repair',
                          };
                        });
                        return false;
                      } else {
                        history.push(
                          `/track/RE${ticket.ticketID}?payment_intent=false`
                        );
                      }
                    }}
                  />
                </div>
              )
            }
            <p className={styles.error}>{personalFormState.error}</p>
          </div>
        </div>
      </Elements>
    );
  }
}

export default observer(PaymentPage);
