import { makeAutoObservable } from 'mobx';

export default class TicketModel {
  ticketID;
  customerID;
  workflowID;
  status;
  model;
  repairType;
  repair;
  latestTask;
  technician;
  bill;
  quotes;
  problem;
  openQuote;
  device;
  promo;
  timeSlot;

  constructor(data) {
    this.update(data);

    makeAutoObservable(this);
  }

  update(data) {
    this.ticketID = data.ticketID;
    this.customerID = data.customerID;
    this.workflowID = data.workflowID;
    this.status = data.status;
    this.model = data.model;
    this.repairType = data.repairType;
    this.repair = data.repair;
    this.latestTask = data.latestTask;
    this.technician = data.technician;
    this.bill = data.bill ?? false;
    this.quotes = data.quotes ?? false;
    this.problem = data.problem;
    this.openQuote = data.openQuote;
    this.device = data.device;
    this.promo = data.promo;
    this.timeSlot = data.timeSlots && data.timeSlots.PICKUP;
  }

  setCustomerID(customerID) {
    this.customerID = customerID;
  }

  setAcceptedQuote(quoteID) {
    this.quotes.forEach((quote, index) => {
      if (quote.quoteID === quoteID) {
        this.quotes[index].accepted = true;
      } else {
        this.quotes[index].accepted = false;
      }
    });
  }

  setTimeSlot(slot) {
    this.timeSlot = slot;
  }

  get isPaid() {
    return this.bill && this.bill.paymentID;
  }
}
