import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useStores } from '../../context/context';
import styles from './MyOrders.module.css';

import LoadingAnimation from '../../components/LoadingAnimation';
import StarRating from '../../components/StarRating';
import BigBtn from '../../components/BigBtn';

function FeedbackPage(props) {
  const history = useHistory();
  const { ticketStore } = useStores();
  const [ticket, setTicket] = useState(false);
  const [stars, setStars] = useState(0);
  const [comments, setComments] = useState();

  useEffect(() => {
    const ticketID = props.match.params.ticketID;
    async function loadTicket() {
      const ticket = await ticketStore.getTicket(ticketID);
      if (ticket) setTicket(ticket);
    }
    if (!ticket) loadTicket();
  }, []);

  const chooseStars = (ev) => {
    var n = parseInt(ev.target.id.replace('star', ''));
    setStars(n);
  };

  const fieldHandler = (ev) => {
    setComments(ev.target.value);
  };

  const handleSubmit = async (ev) => {
    await ticketStore.submitFeedback(ticket.ticketID, stars, comments);
    history.push('/orders');
  };

  return (
    <div className={styles.container}>
      {!ticket ? (
        <LoadingAnimation />
      ) : (
        <div>
          <h1 style={{ marginBottom: 30 }}>Feedback</h1>
          <Link
            to={'/track/RE' + ticket.ticketID}
            className={styles.ticket}
            key={ticket.ticketID}
          >
            <span className={styles.text}>RE{ticket.ticketID}</span>
            <span className={styles.text}>{ticket.model.model}</span>
            <span className={styles.text}>{ticket.repairType.issue}</span>
          </Link>
          <form>
            <h3>How did we perform?</h3>
            <div className='userFeedback'>
              {ticket.technician.firstname} {ticket.technician.surname}:
              <StarRating stars={stars} onClick={chooseStars} />
              <textarea
                placeholder='Comments'
                onChange={fieldHandler}
                value={comments}
              />
            </div>
            <div className='footerContainer'>
              <BigBtn title='SUBMIT FEEDBACK' onClick={handleSubmit} />
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default observer(FeedbackPage);
