import repatchFetch from '../../helpers/fetch';

const route = 'tickets';

const ticketApi = {
  updateTicket: async (params) => {
    return repatchFetch(`${route}/update`, params);
  },
  confirmTicket: async (params) => {
    return repatchFetch(`${route}/confirm`, params);
  },
  getCustomerTickets: async (customerID) => {
    return repatchFetch(`${route}/getCustomerTickets`, {
      customerID: customerID,
    });
  },
  getBill: async (params) => {
    return repatchFetch(`${route}/getBill`, params);
  },
  repatchMyDevice: async (params) => {
    return repatchFetch(`${route}/repatchMyDevice`, params);
  },
  saveTicket: async (params) => {
    return repatchFetch(`${route}/saveLead`, params);
  },
  applyCode: async (params) => {
    return repatchFetch(`${route}/applyCode`, params);
  },
};

export default ticketApi;
