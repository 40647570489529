import React, { useState } from 'react';
import { classnames } from '../helpers/misc';
import RepatchButton from './RepatchButton';
import styles from './styles/CookiePermission.module.css';
import ReactPixel from 'react-facebook-pixel';

function CookiePermission() {
  const [isOpen, setIsOpen] = useState(
    !window.localStorage.getItem('cookiePref') // If a key has already been set, don't open the popup
  );

  const acceptAllCookies = () => {
    window.localStorage.setItem('cookiePref', 'all');
    if (process.env.REACT_APP_APP_ENV === 'production') {
      ReactPixel.grantConsent();
    }
    setIsOpen(false);
  };

  const acceptEssentialCookies = () => {
    window.localStorage.setItem('cookiePref', 'essential');
    setIsOpen(false);
  };

  return (
    <div className={classnames(styles.container, !isOpen && styles.closed)}>
      <div className='column' style={{ flex: 2 }}>
        <p className='large'>Your cookie preferences</p>
        <p className='small'>
          We use cookies to optimise site functionality and help improve user
          experience as described in our{' '}
          <a
            href='https://repatch.co.uk/privacy_policy'
            target='_blank'
            rel='noreferrer'
          >
            Privacy Policy.
          </a>
        </p>
      </div>
      <div
        style={{
          width: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <RepatchButton
          buttonType='small'
          onClick={acceptAllCookies}
          label='Accept'
          style={{ width: '100%', maxWidth: 200, margin: 2 }}
        />
        <RepatchButton
          buttonType='small'
          onClick={acceptEssentialCookies}
          label='Decline'
          style={{ width: '100%', maxWidth: 200, margin: 2 }}
        />
      </div>
    </div>
  );
}

export default CookiePermission;
