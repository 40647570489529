import React from 'react';

// Context is react's idea of "globals"
// You can only have one context associated with any component, in this case "StoreContext"
export const StoreContext = React.createContext(null); // No default

// App.js then wraps everything in a StoreContext's provider to tie the rootStore to it and pass to all child components e.g.:
//   const rootStore = new RootStore();
//   ...
//   <StoreContext.Provider value={rootStore}> ... </StoreContext.Provider>

/*
// CLASS USE:
import { StoreContext } from "../../context/context";
class Home extends React.Component {
  static contextType = StoreContext; // You can only have one :(
  ...
  componentDidMount() {
    this.deviceStore = this.context.deviceStore;
  }
}
*/

// FUNCTION USE:
/*
// This can be wrapped around the whole app in App.js
export const StoreProvider = ({ children, store }) => (
  <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
);
*/
// This returns a hook for use in functions, e.g.
//   import { useStores } from "../../context/context";
//   const { oneOfTheStores } = useStores();
export const useStores = () => {
  const store = React.useContext(StoreContext);
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('useStore must be used within a StoreProvider.');
  }
  return store;
};
