import repatchFetch from '../../helpers/fetch';

export default class UserStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  getUser = async (userID) => {
    var res = await repatchFetch('users/get', { userID: userID });
    return res.user;
  };
}
