import React, { useEffect, useState } from 'react';
import LocationSearch from '../../components/LocationSearch';
import ModelSelect from './components/ModelSelect';
import RepatchButton from '../../components/RepatchButton';
import RepatchInput from '../../components/RepatchInput';
import RepairTypeSelect from './components/RepairTypeSelect';
import sharedStyles from '../../styles/shared.module.css';
import styles from './DevicePage.module.css';
import { useHistory } from 'react-router';
import { useStores } from '../../context/context';
import LoadingSpinner from '../../components/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import Modal from '../../components/Modal';
import { useAnalytics } from '../../context/analytics-context';
import { Helmet } from 'react-helmet';
import { runInAction } from 'mobx';
import { isValidPhone } from '../../helpers/misc';

function DevicePage() {
  const history = useHistory();
  const analytics = useAnalytics();
  const { deviceStore, authStore, ticketStore } = useStores();
  const [currentDevice, setCurrentDevice] = useState(null);
  const [deviceModalOpen, setDeviceModalOpen] = useState(false);
  const [formState, setFormState] = useState({
    address:
      authStore.customer && authStore.customer.address
        ? authStore.customer.address
        : ticketStore.address,
    postcode:
      authStore.customer && authStore.customer.postcode
        ? authStore.customer.postcode
        : ticketStore.postcode,
    modelID: ticketStore.ticketID
      ? deviceStore.customModel
        ? deviceStore.customModel.modelID
        : ticketStore.currentTicket.model.modelID
      : false,
    repairTypeID: ticketStore.ticketID
      ? ticketStore.currentTicket.repairType.repairTypeID
      : false,
    problem: ticketStore.ticketID ? ticketStore.currentTicket.problem : '',
  });

  const [modelFormState, setModelFormState] = useState({
    model: '',
  });

  const [error, setError] = useState(false);
  const [showErrorHelp, setShowErrorHelp] = useState(false);
  useEffect(() => {
    async function getDevice() {
      let [deviceType, manufacturer, device] = history.location.pathname
        .substring(1)
        .split('/');
      deviceType = deviceType.split('-')[0];
      const deviceData = await deviceStore.getDevice({
        deviceType,
        manufacturer,
        device,
      });
      if (deviceStore.customModel) {
        deviceData.models.push(deviceStore.customModel);
      }
      setCurrentDevice(deviceData);
    }

    getDevice();
  }, []);

  const updateFormState = (event) => {
    const { name, value } = event.target;
    setError(false);
    if (name === 'modelID') {
      if (value === '-1') {
        setError('');
        setDeviceModalOpen(true);
        return false;
      } else {
        deviceStore.setCustomModel(false);
      }
    }
    setFormState((prev) => {
      return { ...prev, [name]: value };
    });
  };

  /**
   * Update the form state with the location data from LocationSearch
   * If the location is valid, clear any error messages in the parent state
   * @param {boolean} clearError
   */
  const updateCustomerAddress = (clearError = false) => {
    setFormState((prev) => {
      return {
        ...prev,
        address: ticketStore.address,
        postcode: ticketStore.postcode,
      };
    });
    clearError && setError('');
  };

  const validateForm = () => {
    if (!formState.address || !formState.postcode) {
      setError('Please enter a valid address and postcode.');
      return false;
    } else if (
      (authStore.customer &&
        authStore.customer.phone &&
        !isValidPhone(authStore.customer.phone)) ||
      ((!authStore.customer || !authStore.customer.phone) &&
        !isValidPhone(authStore.phone))
    ) {
      setError('You must enter a valid phone number');
      return false;
    } else if (currentDevice.models.length > 0 && !formState.modelID) {
      setError('Please select the model of your device.');
      return false;
    } else if (!formState.repairTypeID) {
      setError('Please select your repair type.');
      return false;
    }
    return true;
  };

  const updateModelFormState = (event) => {
    const { name, value } = event.target;
    setModelFormState((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const validateModelFormState = () => {
    if (!modelFormState.model) {
      setError('Please enter a model name.');
      return false;
    }
    return true;
  };

  const submitNewModel = async () => {
    if (validateModelFormState()) {
      const model = await deviceStore.addDeviceModel({
        ...modelFormState,
        deviceID: currentDevice.deviceID,
      });
      let newData = currentDevice;
      newData.models.push(model);
      deviceStore.setCustomModel(model);
      setCurrentDevice(currentDevice);
      setFormState((prev) => {
        return { ...prev, modelID: model.modelID };
      });
      setDeviceModalOpen(false);
    }
  };

  const createOrder = async () => {
    if (validateForm()) {
      const ticket = await ticketStore.startTicket({
        ...formState,
        deviceID: currentDevice.deviceID,
      });

      if (ticket) {
        if (ticket.ticketID === 0 && !authStore.isAuthenticated) {
          // We are storing the ticket in state temporarily while we authenticate the user, just push to login screen
          history.push('/login');
        } else {
          // the ticket has already been saved to the database, check that it's ok and then move to the quotes screen
          analytics.logEvent('create_ticket', {
            ticketID: ticket.ticketID,
            deviceID: currentDevice.deviceID,
            repairTypeID: ticket.repairType.repairTypeID,
          });
          history.push(`/ticket`);
        }
      } else if (ticketStore.hasFailed) {
        setError(ticketStore.errorMessage);
        setShowErrorHelp(1);
      } else {
        setError('An unknown error has occurred.');
        setShowErrorHelp(1);
      }
    }
  };

  const setCustomerPhone = (event) => {
    // new phone entries have to be saved in a store so they're persisted during any firebase redirect
    runInAction(() => {
      authStore.phone = event.target.value;
    });
  };

  if (deviceStore.isFetching || !currentDevice) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <Helmet>
          <title>
            Repatch - {currentDevice.manufacturer} {currentDevice.device} Repair
          </title>
        </Helmet>
        <div className={sharedStyles.container}>
          <div className={styles.modelContainer}>
            <h2 className={styles.model}>{currentDevice.device}</h2>
          </div>
          <p className={styles.inputLabel}>Pickup Location</p>
          <LocationSearch onSelectLocation={updateCustomerAddress} />
          <RepatchInput
            label='Contact Number'
            placeholder='Your contact number'
            onChange={setCustomerPhone}
            disabled={authStore.customer && authStore.customer.phone}
            value={
              authStore.customer && authStore.customer.phone
                ? authStore.customer.phone
                : authStore.phone
            }
            autoComplete='phone'
          />
          <div className={styles.selectors}>
            {currentDevice.models.length > 0 && (
              <div className={styles.selectContainer}>
                <div className={styles.modelHeader}>
                  <p className={styles.selectTitle}>Model</p>
                  {currentDevice.helpUrl && (
                    <a
                      className={styles.modelHelp}
                      target='_blank'
                      rel='noreferrer'
                      href={currentDevice.helpUrl}
                    >
                      Find My Model
                    </a>
                  )}
                </div>
                <ModelSelect
                  device={currentDevice}
                  onChange={updateFormState}
                  value={formState.modelID}
                />
              </div>
            )}
            <div className={styles.selectContainer}>
              <p className={styles.selectTitle}>Repair Type</p>
              <RepairTypeSelect
                repairTypes={currentDevice.repairTypes}
                value={formState.repairTypeID}
                onChange={updateFormState}
              />
            </div>
          </div>
          <div>
            <textarea
              className={styles.description}
              placeholder='Describe problem here...'
              onChange={updateFormState}
              name='problem'
              value={formState.problem || ''}
            />
          </div>

          {error && <p className={sharedStyles.error}>{error}</p>}
          {showErrorHelp === 1 ? (
            <p className={sharedStyles.errorHelp}>
              Please try again later or{' '}
              <a
                href='https://repatch.co.uk/contact'
                target='_blank'
                rel='noreferrer'
              >
                contact us
              </a>
            </p>
          ) : (
            false
          )}
          {showErrorHelp === 2 ? (
            <p className={sharedStyles.errorHelpLarge}>
              Please call us on{' '}
              <a href='tel:447475703912' target='_blank' rel='noreferrer'>
                07475 70 3912
              </a>
            </p>
          ) : (
            false
          )}
        </div>
        <RepatchButton
          label='GET A QUOTE'
          style={{
            width: '75vw',
            height: '8vh',
            maxWidth: 400,
            marginTop: '3vh',
            position: 'unset',
          }}
          onClick={createOrder}
          disabled={ticketStore.isFetching}
        />
        <Modal
          isOpen={deviceModalOpen}
          closeModal={() => {
            setDeviceModalOpen(false);
            setError(false); // clear any errors so they don't appear in the main content
          }}
        >
          <h2>Add Device Model</h2>
          <RepatchInput
            label='Model'
            value={modelFormState.model}
            name='model'
            onChange={updateModelFormState}
          />
          {error && <p className={styles.error}>{error}</p>}
          <RepatchButton
            label='Submit'
            onClick={submitNewModel}
            disabled={deviceStore.isFetching}
          />
        </Modal>
      </>
    );
  }
}

export default observer(DevicePage);
