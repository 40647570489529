import { configurePersistable } from 'mobx-persist-store';
// import { observable, computed, makeObservable } from "mobx";

// import authApi from "../api/authApi";

import DeviceStore from './DeviceStore';
import TicketStore from './TicketStore';
import UserStore from './UserStore';
import AuthStore from './AuthStore';
import authApi from '../api/authApi';
import ticketApi from '../api/ticketApi';
import deviceApi from '../api/deviceApi';

configurePersistable({
  storage: window.localStorage,
  expireIn: 86400000,
});

export default class RootStore {
  deviceStore;
  ticketStore;
  userStore;
  AuthStore;

  constructor() {
    this.deviceStore = new DeviceStore(this, deviceApi);
    this.ticketStore = new TicketStore(this, ticketApi);
    this.userStore = new UserStore(this);
    this.authStore = new AuthStore(this, authApi);
  }
}
