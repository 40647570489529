import React from 'react';
import sharedStyles from '../../styles/shared.module.css';
import styles from './Help.module.css';
import { useStores } from '../../context/context';
import HelperBox from '../../components/HelperBox';

function GoogleHelpPage() {
  const { authStore } = useStores();

  return (
    <div className={styles.container}>
      <div
        className={styles.leftContainer}
        style={{ flex: authStore.isAuthenticated ? 'none' : 1 }}
      >
        <div
          className={sharedStyles.container}
          style={{
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <h1>How to find your Google Pixel model number</h1>
          <ol className={styles.helpList}>
            <li>
              Go to <strong>Settings</strong>
            </li>
            <li>
              Tap <strong>About phone</strong>
              <br />
              (or Systems &rarr; About phone for Android &lt; 10)
            </li>
            <li>
              Tap <strong>Regulatory labels</strong>
            </li>
            <li>
              Read the model number
              <br />
              (it will be something like GD1YG)
            </li>
          </ol>
        </div>
      </div>
      {!authStore.isAuthenticated && (
        <div className={styles.rightContainer}>
          <HelperBox />
        </div>
      )}
    </div>
  );
}

export default GoogleHelpPage;
