import React from 'react';
import styles from './styles/RepatchFooter.module.css';

export default function RepatchFooter() {
  return (
    <div className={styles.container}>
      <div className={styles.linksContainer}>
        <a
          href='https://repatch.co.uk/terms'
          target='_blank'
          rel='noreferrer'
          className={styles.link}
        >
          Terms
        </a>
        <a
          href='https://repatch.co.uk/aftercare'
          target='_blank'
          rel='noreferrer'
          className={styles.link}
        >
          Aftercare Policy
        </a>
        <a
          href='https://repatch.co.uk/privacy'
          target='_blank'
          rel='noreferrer'
          className={styles.link}
        >
          Privacy
        </a>
        <a
          href='https://repatch.co.uk/faq'
          target='_blank'
          rel='noreferrer'
          className={styles.link}
        >
          FAQ
        </a>
        <a
          href='https://repatch.co.uk/contact'
          target='_blank'
          rel='noreferrer'
          className={styles.link}
        >
          Contact
        </a>
        <a
          href='https://repatch.co.uk/about'
          target='_blank'
          rel='noreferrer'
          className={styles.link}
        >
          About
        </a>
      </div>
      <p className={styles.link} style={{ fontWeight: 500 }}>
        &copy; Repatch {new Date().getFullYear()}
      </p>
    </div>
  );
}
