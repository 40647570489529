import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import logo from '../assets/images/repatch.jpeg';
import logoPurple from '../assets/images/logo-purple.png';
import styles from './styles/RepatchHeader.module.css';
import { classnames } from '../helpers/misc';
import { useStores } from '../context/context';

function RepatchHeader() {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const menuButtonRef = useRef(null);
  const history = useHistory();
  const { authStore } = useStores();

  useEffect(() => {
    document.addEventListener('mousedown', (event) => {
      // If clicking away from menu and not clicking the close button, set menu to closed
      if (
        menuRef.current &&
        menuButtonRef &&
        !menuRef.current.contains(event.target) &&
        !menuButtonRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
      }
    });

    return () => {
      document.removeEventListener('mousedown', (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setMenuOpen(false);
        }
      });
    };
  }, []);

  const navigateTo = async (route) => {
    setMenuOpen(false);
    if (route !== 'logout') {
      history.push(route);
    } else {
      await authStore.logout();
      history.push('/');
    }
  };

  return (
    <header className={styles.container}>
      <Link to='/'>
        <img src={logo} alt='logo' className={styles.logo} />
      </Link>
      <div
        className={classnames(styles.menu, menuOpen && styles.open)}
        onClick={() => setMenuOpen(!menuOpen)}
        ref={menuButtonRef}
      >
        <div className={styles.menuBurger}></div>
      </div>
      <div
        className={classnames(styles.sideMenu, menuOpen && styles.sideMenuOpen)}
        ref={menuRef}
      >
        <img
          src={logoPurple}
          alt='logo'
          className={classnames(styles.logo, styles.menuLogo)}
        />
        {!authStore.isAuthenticated ? (
          <p className={styles.menuLink} onClick={() => navigateTo('/login')}>
            Login
          </p>
        ) : (
          <>
            <p className={styles.menuLink} onClick={() => navigateTo('/')}>
              New Repair
            </p>
            <p
              className={styles.menuLink}
              onClick={() => navigateTo('/orders')}
            >
              My Orders
            </p>
            <p
              className={styles.menuLink}
              onClick={() => navigateTo('/profile')}
            >
              My Settings
            </p>
            <p className={styles.menuLink} onClick={() => navigateTo('logout')}>
              Logout
            </p>
          </>
        )}
        <a
          href='https://repatch.co.uk/contact'
          target='_blank'
          rel='noreferrer'
          className={styles.menuLink}
        >
          Contact
        </a>
        <a
          href='https://repatch.co.uk/technicians/apply'
          target='_blank'
          rel='noreferrer'
          className={styles.menuLink}
        >
          Become a technician
        </a>
      </div>
    </header>
  );
}

export default observer(RepatchHeader);
