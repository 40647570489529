import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import styles from './PickupTime.module.css';
import 'react-calendar/dist/Calendar.css';
import RepatchButton from '../../components/RepatchButton';
import repatchFetch from '../../helpers/fetch';
import './custom-calendar.css';
import RepatchDropdown from '../../components/RepatchDropdown';
import { dateTextDisplay } from '../../helpers/misc';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useStores } from '../../context/context';

function PickupTime({ ticket }) {
  const { ticketStore } = useStores();

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const maxDate = new Date();
  maxDate.setMonth(today.getMonth() + 1);
  const [isLoading, setIsLoading] = useState(false);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [minDate, setMinDate] = useState(
    today.getHours() > 16 ? tomorrow : today
  );

  useEffect(() => {
    if (!ticket.timeSlot) {
      getSlotsForDate(minDate.toLocaleDateString('en-CA'));
    }

    if (ticketStore.earliestAvailableDay) {
      const unixTime = Date.parse(ticketStore.earliestAvailableDay);
      const dateTime = new Date(unixTime);
      setMinDate(dateTime);
    }
  }, []);

  const getSlotsForDate = async (date) => {
    setIsLoading(true);
    const { rc, msg, timeSlots } = await repatchFetch(
      'tickets/getAvailableTimes',
      {
        date,
      }
    );
    setSelectedDate(date);
    setAvailableTimes(Object.values(timeSlots[date].times));
    setIsLoading(false);
  };

  const chooseTime = async (index) => {
    const time = availableTimes[index];

    const { rc, msg, timeSlot } = await repatchFetch('tickets/chooseTimeSlot', {
      ticketID: ticket.ticketID,
      task: 'PICKUP',
      date: selectedDate,
      time: time === 'ASAP (We will call you)' ? false : time,
      pencil: 1,
    });
    ticket.setTimeSlot(timeSlot);
  };

  const resetTimeSlot = () => {
    ticket.setTimeSlot(false);
    getSlotsForDate(minDate.toLocaleDateString('en-CA'));
  };

  if (ticket.timeSlot) {
    return (
      <div className={styles.pickupText}>
        <div className={styles.titleContainer}>
          <p style={{ fontWeight: '600', marginRight: '1rem' }}>
            Collection Slot
          </p>
          <RepatchButton
            label='Change'
            onClick={resetTimeSlot}
            buttonType='small'
          />
        </div>
        {ticket.timeSlot.asap ? (
          <p>{ticket.timeSlot.timeDesc}</p>
        ) : (
          <p>
            {dateTextDisplay(ticket.timeSlot.day)} {ticket.timeSlot.startTime} -{' '}
            {ticket.timeSlot.endTime}
          </p>
        )}
      </div>
    );
  } else {
    return (
      <div className={styles.calendarContainer}>
        <div className={styles.timePicker}>
          <p>Choose a 3 hour collection window: </p>
          <p className='small' style={{ margin: '1rem 0' }}>
            Our opening times are 10am - 7pm daily
          </p>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <RepatchDropdown
              options={availableTimes.map((time) => {
                if (time === '10:00') {
                  return `${dateTextDisplay(selectedDate)}: ${time} - 13:00`;
                } else if (time === '13:00') {
                  return `${dateTextDisplay(selectedDate)}: ${time} - 16:00`;
                } else if (time === '16:00') {
                  return `${dateTextDisplay(selectedDate)}: ${time} - 19:00`;
                }
              })}
              onChange={chooseTime}
              defaultOption='Choose a time'
            />
          )}
        </div>
        <Calendar
          minDate={minDate}
          maxDate={maxDate}
          defaultValue={minDate}
          onClickDay={(date) =>
            getSlotsForDate(date.toLocaleDateString('en-CA'))
          }
        />
        <p className='small' style={{ marginTop: '1rem' }}>
          Call us on <a href='tel:07475 703912'>07475 703912</a> to arrange a
          pickup more than a month in advance.
        </p>
      </div>
    );
  }
}

export default observer(PickupTime);
