import React, { useState, useContext, useEffect } from 'react';
import { StoreContext } from '../../context/context';
import LoadingAnimation from '../../components/LoadingAnimation';
import Task from '../../components/Task';
import sharedStyles from '../../styles/shared.module.css';
import './Tracking.css';
import RepatchButton from '../../components/RepatchButton';
import { useHistory } from 'react-router';
import PromoCode from '../../components/PromoCode';
import { dateTextDisplay } from '../../helpers/misc';
import { useLocation } from 'react-router-dom';

const TrackingPage = (props) => {
  // Inside a function, state management happens via the useState hook..
  const [status, setStatus] = useState('loading');
  const [ticket, setTicket] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [promo, setPromo] = useState(false);
  // Inside a function, context comes in via the useContext hook...
  const store = useContext(StoreContext);
  const history = useHistory();
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const paymentIntentID = params.get('payment_intent');

  // Inside a function, useEffect is similar to componentDidMount() in a class
  useEffect(() => {
    const ticketID = parseInt(props.match.params.ticketID);

    // Inside a function, you need to be careful to not re-request everything on every re-render
    async function loadTicket() {
      if (paymentIntentID) {
        await store.ticketStore.confirm(
          ticketID,
          paymentIntentID,
          store.authStore.customer.email
        );
        if (store.ticketStore.hasFailed) {
          return;
        }
        // push without the payment intent query param so confirm isn't accidentally called again
        history.push(`/track/RE${ticketID}`);
      }

      await store.ticketStore.getTicket(ticketID);
      if (!store.ticketStore.hasFailed) {
        setTicket(store.ticketStore.currentTicket);
      } else {
        history.push('/orders');
      }
    }
    loadTicket();
  }, []);

  useEffect(() => {
    async function loadTasks() {
      var { ticketTasks, ticketPromo } = await store.ticketStore.getTasks(
        ticket.ticketID
      );
      // filter out the deposit payment task for openQuotes
      setTasks(ticketTasks.filter((task) => task['task'] !== 'DEPOSIT'));
      setPromo(ticketPromo);
    }
    if (ticket) {
      loadTasks();
    }
  }, [ticket]);

  useEffect(() => {
    if (ticket && tasks.length > 0) {
      setStatus('loaded');
    }
  }, [tasks]);

  const goToBill = () => {
    history.push(`/ticket/pay?ticketID=${ticket.ticketID}`);
  };

  if (status === 'loading') {
    return <LoadingAnimation />;
  } else {
    return (
      <>
        {promo && <PromoCode promo={promo} />}
        <div className={sharedStyles.container}>
          <div className='trackingPageHeading'>
            <h2>
              {ticket.device.device} {ticket.repairType.issue}
            </h2>
            <p>Order confirmation number: RE{ticket.ticketID}</p>
            {ticket.timeSlot && (
              <div style={{ marginTop: 10 }}>
                <p style={{ fontWeight: '600' }}>Your Collection Slot:</p>
                <p>
                  {dateTextDisplay(ticket.timeSlot.day)}{' '}
                  {ticket.timeSlot.startTime} - {ticket.timeSlot.endTime}
                </p>
              </div>
            )}
          </div>
          <hr />

          {
            // only show if there is an unpaid bill and it is not an accepted open quote with an unpaid deposit
            ticket.bill &&
              !ticket.bill.paymentID &&
              ticket.status !== 'ACCEPTED' && (
                <RepatchButton
                  label={`There is an additional charge of £${
                    ticket.bill.amountInPence / 100
                  } for your repair. Click here to pay now.`}
                  onClick={goToBill}
                />
              )
          }
          <div className='taskList'>
            {tasks.map((task, index) => {
              const nextNotStarted =
                index + 1 === tasks.length
                  ? false
                  : tasks[index + 1].status === 'NOTSTARTED';
              return (
                <Task
                  task={task}
                  first={index + 1 === tasks.length}
                  nextNotStarted={nextNotStarted}
                  key={'task' + task.taskID}
                />
              );
            })}
          </div>
        </div>
      </>
    );
  }
};
// Have to wrap in withRouter in order to use url params in class
export default TrackingPage;
