import React from 'react';
import styles from './styles/HelperBox.module.css';
import stepOneImage from '../assets/images/helper1.png';
import stepTwoImage from '../assets/images/helper2.png';
import stepThreeImage from '../assets/images/helper3.png';
import { classnames } from '../helpers/misc';

function HelperBox() {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>HOW IT WORKS</h1>
      <div className={styles.stepContainer}>
        <img src={stepOneImage} alt='step-one' className={styles.image} />
        <div>
          <div className={styles.subHeader}>
            <div className={styles.lozenge}>
              <p className={classnames(styles.lozengeText, styles.text)}>
                STEP
              </p>
              <p className={classnames(styles.lozengeText, styles.number)}>1</p>
            </div>
            <h2 className={styles.subHeading}>Request</h2>
          </div>
          <p className={styles.mainText}>
            Select to repair your phone, tablet or laptop and let us know what
            the issue is with your device.
          </p>
        </div>
      </div>

      <div className={styles.stepContainer}>
        <img src={stepTwoImage} alt='step-one' className={styles.image} />
        <div>
          <div className={styles.subHeader}>
            <div className={styles.lozenge}>
              <p className={classnames(styles.lozengeText, styles.text)}>
                STEP
              </p>
              <p className={classnames(styles.lozengeText, styles.number)}>2</p>
            </div>
            <h2 className={styles.subHeading}>Match</h2>
          </div>
          <p className={styles.mainText}>
            You will be matched with a skilled technician in your local area who
            will be responsible for fixing your device.
          </p>
        </div>
      </div>

      <div className={styles.stepContainer}>
        <img src={stepThreeImage} alt='step-one' className={styles.image} />
        <div>
          <div className={styles.subHeader}>
            <div className={styles.lozenge}>
              <p className={classnames(styles.lozengeText, styles.text)}>
                STEP
              </p>
              <p className={classnames(styles.lozengeText, styles.number)}>3</p>
            </div>
            <h2 className={styles.subHeading}>Collection</h2>
          </div>
          <p className={styles.mainText}>
            A driver will pick up your device from your home or work and once it
            is fixed, it will be delivered back to you.
          </p>
        </div>
      </div>
    </div>
  );
}

export default HelperBox;
