import { makeAutoObservable } from 'mobx';

export default class CustomerModel {
  customerID;
  email;
  authProviderUID;
  authProvider;
  firstname;
  surname;
  address;
  postcode;
  phone;
  receivePromo;

  constructor(data) {
    this.update(data);

    makeAutoObservable(this);
  }

  update(data) {
    // Required fields
    this.customerID = data.customerID;
    this.email = data.email;
    this.authProviderUID = data.authProviderUID;
    this.authProvider = data.authProvider;

    // Optional fields - All null fields that populate an input should be converted to empty string to avoid warnings
    this.firstname = data.firstname ?? '';
    this.surname = data.surname ?? '';
    this.address = data.address ?? '';
    this.postcode = data.postcode ?? '';
    this.phone = data.phone ?? '';
    this.receivePromo = data.receivePromo ?? 0;
  }
}
