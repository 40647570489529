import React from 'react';
import { classnames } from '../../../helpers/misc';
import styles from './Quote.module.css';

function Quote({ ticket, quote, isSelected, onClick }) {
  return (
    <div
      style={{ flexDirection: 'column' }}
      className={classnames(styles.container, isSelected && styles.selected)}
      onClick={onClick}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className={styles.imgContainer}>
          {quote.imgUrl && (
            <img
              src={`${process.env.REACT_APP_API_URL}${quote.imgUrl}`}
              alt='repair-icon'
              className={styles.image}
            />
          )}
        </div>
        <div className={styles.infoContainer}>
          <div className={classnames(styles.row, styles.top)}>
            <p className={styles.title}>{quote.title}</p>
            {quote.openQuote ? (
              <div>
                <p className={styles.price}>
                  £{quote.openMin.split('.')[0]} - £
                  {quote.openMax.split('.')[0]}{' '}
                  {/* trim pence values from estimates */}
                </p>
                {ticket.promo && (
                  <p className={classnames(styles.price, styles.originalPrice)}>
                    £{quote.originalOpenMin.split('.')[0]} - £
                    {quote.originalOpenMax.split('.')[0]}{' '}
                    {/* trim pence values from estimates */}
                  </p>
                )}
              </div>
            ) : (
              <div>
                <p className={styles.price}>£{quote.amount}</p>
                {ticket && ticket.promo && (
                  <p className={classnames(styles.price, styles.originalPrice)}>
                    £{quote.originalAmount}
                  </p>
                )}
              </div>
            )}
          </div>
          <div className={classnames(styles.column, styles.bottom)}>
            <p className={styles.variation} style={{ fontWeight: '500' }}>
              {quote.variation}
            </p>
            <p className={styles.variation}>{quote.toolTip}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quote;
