import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

import { StoreContext } from './context/context';
import RootStore from './data/stores/RootStore';

window.document.title = 'Repatch';

// Initialise all the stores here, so we can perform any critical state hydrations before the app renders
const rootStore = new RootStore();

// Check that the authStore has fetched and hydrated before render
rootStore.authStore.checkForPersistedUser().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <StoreContext.Provider value={rootStore}>
        <App />
      </StoreContext.Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
