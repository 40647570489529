// global repatch version of fetch

// import firebase from "firebase/app";
// import "firebase/performance";

export default function repatchFetch(endpoint, params) {
  let url = `${process.env.REACT_APP_API_URL}api/${endpoint}`;

  let postData = JSON.stringify(params);
  // const perf = firebase.performance();

  const opts = {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: postData,
  };
  if (process.env.NODE_ENV === 'development') {
    console.log(`Calling:`, url, opts.body);
  }
  // const trace = perf.trace(`FETCH_${endpoint}_${params.action}`);
  // trace.start();

  return fetch(url, opts)
    .then((response) => response.json())
    .then(async (data) => {
      if (typeof data.rc === 'undefined') {
        // The request has failed, throw an error with metadata
        const error = new Error('API Request Failed');
        error.metadata = {
          url,
          postData,
          status: data.status,
          serverMsg: data.detail,
        };
        throw error;
      } else {
        if (data.rc === 2) {
          // rc==2 usually equates to a HTTP code 4XX style error, log it anyway
          const error = new Error('API Invalid Params');
          error.metadata = {
            url,
            postData,
            rc: data.rc,
            serverMsg: data.msg,
          };
          throw error;
        } else if (data.rc === -1) {
          throw new Error('logged-out');
        } else if (data.rc === 1) {
          console.log('API warning:', data);
        } else if (process.env.NODE_ENV === 'development') {
          console.log('fetch ok:', data);
        }
        return data;
      }
    });
}
