import React, { useEffect } from 'react';
import './App.css';
import ROUTES, { RenderRoutes } from './helpers/routes';
import { BrowserRouter as Router } from 'react-router-dom';
import RepatchFooter from './components/RepatchFooter';
import RepatchHeader from './components/RepatchHeader';
import { AuthProvider } from './context/firebase-context';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AnalyticsProvider } from './context/analytics-context';
import CookiePermission from './components/CookiePermission';
import { IntercomProvider } from 'react-use-intercom';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

// The main app
// Everything is also wrapped in a StoreContext to provide access to globals
function App() {
  useEffect(() => {
    // Check if a promo has been passed into the entry URL and save for later use
    const urlParams = new URLSearchParams(window.location.search);
    const promoCode = urlParams.get('promo');
    if (promoCode) {
      window.sessionStorage.setItem('promo', promoCode);
    }
  }, []);
  return (
    <div className='App'>
      <AuthProvider>
        <Router>
          <AnalyticsProvider>
            <IntercomProvider appId={'rvfmsapv'} autoBoot={!window.isNativeApp}>
              {!window.isNativeApp && <RepatchHeader />}
              <div className='App-body'>
                <Elements stripe={stripePromise}>
                  <RenderRoutes routes={ROUTES} />
                </Elements>
              </div>
              {!window.isNativeApp && <RepatchFooter />}
              <CookiePermission />
            </IntercomProvider>
          </AnalyticsProvider>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
