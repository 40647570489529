import React, { useEffect } from 'react';
import sharedStyles from '../../styles/shared.module.css';
import styles from './MyOrders.module.css';

import { useStores } from '../../context/context';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { classnames } from '../../helpers/misc';
import LoadingSpinner from '../../components/LoadingSpinner';
import RepatchButton from '../../components/RepatchButton';

function MyOrders() {
  const { ticketStore } = useStores();
  const history = useHistory();

  useEffect(() => {
    async function fetchMyOrders() {
      await ticketStore.getCustomerTickets();
    }

    fetchMyOrders();
  }, []);

  const goToTicket = (ticket) => {
    // If there is no latestTaks, this is still an unaccepted quote, direct to payment
    ticketStore.ticketID = ticket.ticketID;
    if (ticket.status === 'STARTED' || ticket.status === 'CONTACTED') {
      history.push('/ticket');
    } else {
      history.push('/track/RE' + ticket.ticketID);
    }
  };

  if (ticketStore.isFetching) {
    return (
      <div className={sharedStyles.container} style={{ alignItems: 'center' }}>
        <LoadingSpinner />
      </div>
    );
  }

  const parseTicketStatus = (status) => {
    switch (status) {
      case 'STARTED':
      case 'CONTACTED':
      case 'NEW':
        return 'New Quote';
      case 'ACCEPTED':
      case 'PAID':
        return 'Preparing Your Repair';
      case 'COLLECTED':
        return 'Device Being Repaired';
      case 'AWAITINGPAYMENT':
        return 'Awaiting Payment';
      case 'COMPLETED':
      case 'REPAIRED':
        return 'Device Repaired';
      default:
        return status;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.emptyOrdersContainer}>
        <p className={styles.emptyText}>
          {ticketStore.tickets.length > 0
            ? `(${ticketStore.tickets.length}) ${
                ticketStore.tickets.length > 1 ? 'Orders' : 'Order'
              } In Your List`
            : 'You Do Not Have Any Orders Yet'}
        </p>
        <p className={classnames(styles.emptyText, styles.emptyTextSub)}>
          {ticketStore.tickets.length > 0
            ? `Click The Button To Repair A New Device`
            : 'Click The Button Below To Get Started'}
        </p>
        <RepatchButton
          label='New Orders'
          style={{ width: '100%' }}
          onClick={() => history.push('/')}
        />
      </div>
      <div>
        <h1 className={styles.pageTitle}>My Orders</h1>
        {ticketStore.tickets.map((ticket) => (
          <div
            className={styles.ticket}
            key={ticket.ticketID}
            onClick={() => goToTicket(ticket)}
          >
            <span
              className={classnames(styles.text)}
              style={{ fontWeight: 800 }}
            >
              RE{ticket.ticketID}{' '}
              <span
                className={classnames(styles.text)}
                style={{
                  marginBottom: 0,
                  marginLeft: '0.5rem',
                  fontWeight: 600,
                }}
              >
                {' '}
                {ticket.device.device}
              </span>
            </span>
            <span className={classnames(styles.text)}>
              {ticket.repairType.issue}
            </span>
            <span className={classnames(styles.text)} style={{ color: 'grey' }}>
              {parseTicketStatus(ticket.status)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default observer(MyOrders);
