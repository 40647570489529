import React from 'react';
import { useState, useContext, createContext, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { handleFirebaseAuthError } from '../helpers/auth';

if (firebase.apps.length === 0) {
  firebase.initializeApp({
    apiKey: 'AIzaSyBCLfd2u4QZnJ0PKk-2v3hNRmHLW4MB3z0',
    authDomain: 'repatch-app.firebaseapp.com',
    projectId: 'repatch-app',
    storageBucket: 'repatch-app.appspot.com',
    messagingSenderId: '777728941775',
    appId: '1:777728941775:web:235e10a6a12f38ac52e1ba',
    measurementId: 'G-WFDX3B7ZVW',
    databaseURL:
      'https://repatch-app-default-rtdb.europe-west1.firebasedatabase.app',
  });
}

const AuthContext = createContext();
const auth = firebase.auth;

export function AuthProvider({ children }) {
  const firebaseAuth = useAuthProvider();
  return (
    <AuthContext.Provider value={firebaseAuth}>{children}</AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
};

const useAuthProvider = () => {
  const [user, setUser] = useState(null);

  const signInWithProvider = async (
    authProvider,
    email = false,
    password = false
  ) => {
    try {
      switch (authProvider) {
        case 'password':
          await signInWithPassword(email, password);
          break;
        case 'password-signup':
          await signUpWithPassword(email, password);
          break;
        case 'google':
          await authenticateWithGoogle();
          break;
        case 'facebook':
          await authenticateWithFacebook();
          break;
        case 'apple':
          await authenticateWithApple();
          break;
        default:
          break;
      }
    } catch (e) {
      const errorMessage = handleFirebaseAuthError(e);
      throw new Error(errorMessage);
    }
  };

  const signOut = async () => {
    return auth().signOut();
  };

  const signInWithPassword = (email, password) => {
    return auth().signInWithEmailAndPassword(email, password);
  };

  const signUpWithPassword = async (email, password) => {
    return auth().createUserWithEmailAndPassword(email, password);
  };

  async function authenticateWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();

    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile');

    return auth().signInWithRedirect(provider);
  }

  async function authenticateWithFacebook() {
    const provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('email');
    provider.addScope('public_profile');

    // Facebook login has to use a redirect sign in for compatibility with the Facebook browser (yeah...)
    return auth().signInWithRedirect(provider);
  }

  async function authenticateWithApple() {
    const provider = new firebase.auth.OAuthProvider('apple.com');

    provider.addScope('email');
    provider.addScope('name');

    return auth().signInWithRedirect(provider);
  }

  const updatePassword = async (email, currentPassword, newPassword) => {
    try {
      // Have to re-authenticate before changing password
      await signInWithPassword(email, currentPassword);
      await auth().currentUser.updatePassword(newPassword);
      return true;
    } catch (e) {
      const errorMessage = handleFirebaseAuthError(e);
      throw new Error(errorMessage);
    }
  };

  const sendVerificationEmail = async () => {
    try {
      await user.sendEmailVerification();
    } catch (e) {
      const message = handleFirebaseAuthError(e.message);
      throw new Error(message);
    }
  };

  const sendPasswordResetEmail = async (email) => {
    try {
      await auth().sendPasswordResetEmail(email);
    } catch (e) {
      const message = handleFirebaseAuthError(e.message);
      throw new Error(message);
    }
  };

  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser);
      } else {
        setUser(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return {
    user,
    signOut,
    signInWithProvider,
    updatePassword,
    sendPasswordResetEmail,
    sendVerificationEmail,
  };
};
